import React, { useState, useEffect } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { httpService } from "../../base/services/httpService.service";
import Select from "react-select";
import { LocalStorageService } from "../../base/services/local-storage.service";
import { Formik, Form, Field } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { BackIcon, FileImport } from "../../assets/icons";
import { Input, Upload } from "antd";
const initialValues = {
   FullName: "",
   PersonalStatement: "",
   NationalityId: null,
   ResidenceCountryId: null,
   OperatingCountriesIds: [],
   LinkedInProfile: "",
   PhoneNumber: ""
};

function RegistrationOfHumanitarianStepThree() {
   const [operationalCountries, setOperationalCountries] = useState([]);
   const [residenceCountry, setResidenceCountry] = useState(null);
   const [nationalityOptions, setNationalityOptions] = useState([]);
   const navigate = useNavigate();

   const validationSchema = Yup.object().shape({
      FullName: Yup.string().required("Full name is required"),
      NationalityId: Yup.string()
         .nullable()
         .required("Nationality is required"),
      ResidenceCountryId: Yup.string()
         .nullable()
         .required("Country of residence is required")
   });

   useEffect(() => {
      httpService.get(`/Countries/nationalities`, (data) => {
         const nationalityData = data.data.map((nationality) => {
            return {
               value: nationality.id,
               label: nationality.name
            };
         });
         setNationalityOptions(nationalityData);
      });
      httpService.get(`/Countries`, (data) => {
         const countryData = data.data.map((country) => {
            return {
               value: country.id,
               label: country.name
            };
         });
         setResidenceCountry(countryData);
         setOperationalCountries(countryData);
      });
   }, []);

   const handleContinue = async (values) => {
      const formattedValues = {
         ...values,
         NationalityId: values.NationalityId.value,
         ResidenceCountryId: values.ResidenceCountryId.value,
         OperatingCountriesIds: values.OperatingCountriesIds.map((country) => country.value)
      };

      LocalStorageService.setItem("consultantProfileData", formattedValues);

      navigate("/auth/expert-registration-step-four");
   };

   return (
      <div className="p-8 p-md-10 pt-xl-10 pb-xl-20 pl-xl-40 pr-xl-40">
         <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleContinue(values)}
            validationSchema={validationSchema}
         >
            {({ values, handleChange, setFieldValue, errors, touched }) => (
               <Form>
                  <div
                     style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "40px",
                        gap: "32px"
                     }}
                  >
                     <p
                        style={{
                           fontSize: "26px",
                           color: "#4158CF",
                           fontWeight: 600,
                           margin: "0px"
                        }}
                     >
                        Let's start with your profile information
                     </p>
                     <div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                           <div className="form-group fv-plugins-icon-container">
                              <label
                                 style={{
                                    fontSize: "16px",
                                    color: "#3A3737",
                                    fontWeight: 500,
                                    marginBottom: "10px"
                                 }}
                              >
                                 <span style={{ color: "red" }}>* </span>
                                 Full name
                              </label>
                              <Field
                                 placeholder="Enter your full name"
                                 className={`form-control`}
                                 type="text"
                                 name="FullName"
                                 autoComplete="off"
                              />
                              {errors.FullName && touched.FullName && (
                                 <div className="text-danger">{errors.FullName}</div>
                              )}
                           </div>
                           <div className="form-group fv-plugins-icon-container">
                              <label
                                 style={{
                                    fontSize: "16px",
                                    color: "#3A3737",
                                    fontWeight: 500,
                                    marginBottom: "10px"
                                 }}
                              >
                                 Personal statement/motivation
                              </label>
                              <div className="password-section">
                                 <Input.TextArea
                                    placeholder="Enter your personal statement"
                                    type="text"
                                    name="PersonalStatement"
                                    className="form-control"
                                    value={values.PersonalStatement}
                                    onChange={(e) =>
                                       setFieldValue("PersonalStatement", e.target.value)
                                    }
                                    autoComplete="off"
                                 />
                              </div>

                              {errors.PersonalStatement && touched.PersonalStatement && (
                                 <div className="text-danger">{errors.PersonalStatement}</div>
                              )}
                           </div>
                           <div className="form-group fv-plugins-icon-container">
                              <label
                                 style={{
                                    fontSize: "16px",
                                    color: "#3A3737",
                                    fontWeight: 500,
                                    marginBottom: "10px"
                                 }}
                              >
                                 <span style={{ color: "red" }}>* </span>
                                 Nationality
                              </label>
                              <div className="password-section">
                                 <Select
                                    className="basic-single"
                                    options={nationalityOptions}
                                    placeholder="Select your nationality"
                                    isSearchable
                                    onChange={(selectedOption) =>
                                       setFieldValue("NationalityId", selectedOption)
                                    }
                                    value={values.NationalityId}
                                    autoComplete="off"
                                 />
                              </div>

                              {errors.NationalityId && touched.NationalityId && (
                                 <div className="text-danger">{errors.NationalityId}</div>
                              )}
                           </div>
                        </div>
                     </div>
                     <div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                           <div className="form-group fv-plugins-icon-container">
                              <label
                                 style={{
                                    fontSize: "16px",
                                    color: "#3A3737",
                                    fontWeight: 500,
                                    marginBottom: "10px"
                                 }}
                              >
                                 <span style={{ color: "red" }}>* </span>
                                 Country of residence
                              </label>
                              <Select
                                 options={residenceCountry}
                                 placeholder="Select organization's country of residence"
                                 isSearchable
                                 onChange={(selectedOption) =>
                                    setFieldValue("ResidenceCountryId", selectedOption)
                                 }
                                 value={values.ResidenceCountryId}
                              />
                              {errors.ResidenceCountryId && touched.ResidenceCountryId && (
                                 <div className="text-danger">{errors.ResidenceCountryId}</div>
                              )}
                           </div>
                           <div className="form-group fv-plugins-icon-container">
                              <label
                                 style={{
                                    fontSize: "16px",
                                    color: "#3A3737",
                                    fontWeight: 500,
                                    marginBottom: "10px"
                                 }}
                              >
                                 Operating countries
                              </label>

                              <Select
                                 options={operationalCountries}
                                 placeholder="Select operating countries"
                                 isMulti
                                 isSearchable
                                 onChange={(selectedOptions) =>
                                    setFieldValue("OperatingCountriesIds", selectedOptions)
                                 }
                                 value={values.OperatingCountriesIds}
                              />
                              {errors.OperatingCountriesIds && touched.OperatingCountriesIds && (
                                 <div className="text-danger">{errors.OperatingCountriesIds}</div>
                              )}
                           </div>
                           <div className="form-group fv-plugins-icon-container">
                              <label
                                 style={{
                                    fontSize: "16px",
                                    color: "#3A3737",
                                    fontWeight: 500,
                                    marginBottom: "10px"
                                 }}
                              >
                                 LinkedIn profile
                              </label>
                              <div className="password-section">
                                 <Field
                                    placeholder="Enter your LinkedIn profile"
                                    type="text"
                                    name="LinkedInProfile"
                                    className="form-control"
                                    autoComplete="off"
                                 />
                                 {errors.LinkedInProfile && touched.LinkedInProfile && (
                                    <div className="text-danger">{errors.LinkedInProfile}</div>
                                 )}
                              </div>
                           </div>
                           <div className="form-group fv-plugins-icon-container">
                              <label
                                 style={{
                                    fontSize: "16px",
                                    color: "#3A3737",
                                    fontWeight: 500,
                                    marginBottom: "10px"
                                 }}
                              >
                                 Phone number
                              </label>

                              <Input
                                 autoComplete="off"
                                 placeholder="Enter phone number"
                                 type="tel"
                                 name="PhoneNumber"
                                 value={values.PhoneNumber}
                                 onChange={(e) => setFieldValue("PhoneNumber", e.target.value)}
                              />
                              {errors.PhoneNumber && touched.PhoneNumber && (
                                 <div className="text-danger">{errors.PhoneNumber}</div>
                              )}
                           </div>
                        </div>
                     </div>

                     <div
                        style={{
                           display: "flex",
                           alignItems: "center",
                           gap: "40px",
                           justifyContent: "center"
                        }}
                     >
                        <button type="submit" className={`btn sign-btn font-weight-bold my-3`}>
                           Continue
                        </button>
                     </div>
                  </div>
               </Form>
            )}
         </Formik>
      </div>
   );
}
export default RegistrationOfHumanitarianStepThree;
