import React, { useState, useEffect } from "react";
import { Divider, Radio, Input, Empty } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import AuthService from "../../../../../../app/base/services/authentication.service";
import { UserRolesEnum } from "../../../../../../app/pages/auth/userRoles.enum";
import { SearchOutlined } from "@material-ui/icons";

function MenuAdminActionPlanList({ data, kind }) {
   const [selectedAssessment, setSelectedAssessment] = useState(null);
   const [loading, setLoading] = useState(true);
   const [initialLoadComplete, setInitialLoadComplete] = useState(false);
   const [searchQuery, setSearchQuery] = useState("");
   const navigate = useNavigate();
   const location = useLocation();
   const isSuperAdmin = AuthService.isAuthorized([UserRolesEnum.SuperAdmin]);

   useEffect(() => {
      if (data.length > 0 && !initialLoadComplete) {
         const urlAssessmentId = location.pathname.split("/").pop();
         const initialAssessmentId = data?.length ? data[0]?.id : '';
         if (urlAssessmentId !== initialAssessmentId) {
            const url = `/workspace/results/action-plan/${initialAssessmentId}`;
            setSelectedAssessment(initialAssessmentId);
            localStorage.setItem("selectedAssessmentId", initialAssessmentId);
            navigate(url);
         } else {
            setSelectedAssessment(urlAssessmentId);
         }

         setInitialLoadComplete(true);
         setLoading(false);
      }
   }, [data, location.pathname, initialLoadComplete, navigate]);

   const handleAssessmentChange = (assessmentId) => {
      setSelectedAssessment(assessmentId);
      localStorage.setItem("selectedAssessmentId", assessmentId);

      if (kind === "action-plan") {
         navigate(`/workspace/results/action-plan/${assessmentId}`);
      }
   };

   const clickedCard = (assessmentId) => {
      handleAssessmentChange(assessmentId);
   };

   const filteredData = data.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
   );

   return (
      <div style={{ padding: "1.25rem" }}>
         <div className="assessment-action-plan-title">Assessments</div>
         <div className="assessment-action-plan-description">
            <div>
               {isSuperAdmin
                  ? "You need to assign the necessary resources for each survey in an assessment you created. This helps all organizations taking those assessments to improve their weaker areas."
                  : "Here you will find an action plan based on your results in each assessment."}
            </div>
            <div>
               {isSuperAdmin
                  ? "Select one assessment to see its resources in detail:"
                  : "Select one to see the plan in detail."}
            </div>
            <div style={{ padding: "20px 0px" }}>
               <Input
                  autoComplete="off"
                  placeholder="Search for an assessment"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  prefix={<SearchOutlined style={{ color: "#a8a8a8" }} />}
               />
            </div>
         </div>
         <div className="assessment-cards-wrapper">
            {filteredData.length > 0 ? (
               <Radio.Group
                  value={selectedAssessment}
                  onChange={(e) => handleAssessmentChange(e.target.value)}
                  className="custom-radio-group"
               >
                  {filteredData.map((item) => (
                     <div
                        className={`custom-assessment-card ${
                           selectedAssessment === item?.id ? "activeAssessmentCard" : ""
                        }`}
                        key={item.id}
                        onClick={() => clickedCard(item?.id)}
                     >
                        <div className="custom-card-upper">
                           <h3 className="assessment-name">{item?.name}</h3>
                           <Radio key={item.id} value={item.id}></Radio>
                        </div>
                        <Divider style={{ marginTop: "30px" }} />
                        <div className="custom-card-lower" style={{ paddingRight: "20px" }}>
                           <h3>Number of Resources:</h3>
                           <span>{item.numberOfResources}</span>
                        </div>
                     </div>
                  ))}
               </Radio.Group>
            ) : (
               <Empty description="No data" />
            )}
         </div>
      </div>
   );
}

export default MenuAdminActionPlanList;
