import https from "../utils/http";

export const httpService = {
   get(path, callback, errCallback) {
      return https.get(path).then((response) => {
         if (response.data) {
            callback !== undefined && callback !== null && callback(response.data);
         } else {
            errCallback !== undefined && callback !== null && errCallback(response.data);
            return response;
         }
      });
   },

   post(path, payload, callback, errCallback) {
      return https.post(path, payload).then((response) => {
         if (response.data.success) {
            callback !== undefined && callback !== null && callback(response.data);
         } else {
            errCallback && errCallback(response.data);
            // errCallback !== undefined && callback !== null && errCallback(response.data);
            // return response;
         }
         return response;
      });
   },

   upload(path, payload, callback, errCallback) {
      return https
         .post(path, payload, {
            headers: {
               "Content-Type":
                  "multipart/form-data; boundary=----WebKitFormBoundaryTf4LfzAOGWPtcNQl"
            }
         })
         .then((response) => {
            if (response.data.success) {
               callback !== undefined && callback !== null && callback(response.data);
            } else {
               errCallback !== undefined && callback !== null && errCallback(response.data);
               return response;
            }
         });
   },

   uploadFileForQuestion(path, payload, params, callback, errCallback) {
      return https
         .post(path, payload, {
            params: {
               questionId: params.questionId
            },
            headers: {
               "Content-Type":
                  "multipart/form-data; boundary=----WebKitFormBoundaryTf4LfzAOGWPtcNQl"
            }
         })
         .then((response) => {
            if (response.data.success) {
               callback !== undefined && callback !== null && callback(response.data);
            } else {
               errCallback !== undefined && callback !== null && errCallback(response.data);
               return response;
            }
         });
   },

   update(path, payload, callback, errCallback) {
      return https.put(path, payload).then((response) => {
         if (response.data.success) {
            callback !== undefined && callback !== null && callback(response.data);
         } else {
            errCallback !== undefined && callback !== null && errCallback(response.data);
            return response;
         }
      });
   },
   put(path, payload, callback, errCallback) {
      return https.put(path, payload).then((response) => {
         if (response.data.success) {
            callback !== undefined && callback !== null && callback(response.data);
         } else {
            errCallback !== undefined && callback !== null && errCallback(response.data);
            return response;
         }
      });
   },

   delete(path, payload, callback, errCallback) {
      return https.delete(path, { data: payload }).then((response) => {
         if (response.data.success) {
            callback !== undefined && callback !== null && callback(response.data);
         } else {
            errCallback !== undefined && callback !== null && errCallback(response.data);
            return response;
         }
      });
   }
};
