import { UserRolesEnum } from "./pages/auth/userRoles.enum";
import {
   GroupPeople,
   Assessments,
   DocumentHandHeldWriting,
   DashboardWorkspace,
   Requests,
   Organizations,
   GroupPeopleInactive,
   DocumentHandHeldWritingActive,
   DashboardWorkspaceActive,
   AssessmentsActive,
   RequestsActive,
   OrganizationsActive
} from "./assets/icons";
export const menu = {
   layout: [
      // {
      //   id: "module1",
      //   title: "Module 1",
      //   type: "module",
      //   icon: "flaticon2-open-box",
      //   url: "/module1",
      //   role: [UserRolesEnum.SuperAdmin],
      //   subMenus: [
      //     {
      //       id: "menu-item-1",
      //       title: "Page1",
      //       type: "item",
      //       icon: "flaticon2-open-box",
      //       url: "/module1/page1",
      //       role: [UserRolesEnum.SuperAdmin],
      //     },
      //     {
      //       id: "admin_page_0132",
      //       title: "Divider 1",
      //       type: "divider",
      //       role: [UserRolesEnum.SuperAdmin],
      //     },
      //     {
      //       id: "parent-menu-1",
      //       title: "Parent Menu 1",
      //       type: "item",
      //       icon: "flaticon2-gear",
      //       url: "/module1/division",
      //       role: [UserRolesEnum.SuperAdmin],
      //       children: [
      //         {
      //           id: "child-1",
      //           title: "Child 1",
      //           type: "item",
      //           icon: "flaticon-twitter-logo",
      //           url: "/module1/division/page2",
      //           role: [UserRolesEnum.SuperAdmin],
      //         },
      //         {
      //           id: "child-parent-1",
      //           title: "Child Parent 1",
      //           type: "item",
      //           icon: "flaticon-twitter-logo",
      //           url: "/module1/division/division2",
      //           role: [UserRolesEnum.SuperAdmin],
      //           children: [
      //             {
      //               id: "child-2",
      //               title: "child 2-1",
      //               type: "item",
      //               icon: "file",
      //               url: "/module1/division/division2/page3",
      //               role: [UserRolesEnum.SuperAdmin],
      //             },
      //             {
      //               id: "child-3",
      //               title: "child 2-2",
      //               type: "item",
      //               icon: "file",
      //               url: "/module1/division/division2/page4",
      //               role: [UserRolesEnum.SuperAdmin],
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
         id: "self-assessment",
         title: "Self Assessment",
         isDisabled: false,
         type: "dynamic_list",
         kind: "self-assessment",
         api: "/assesment/self/getAll/",
         icon: "flaticon2-crisp-icons",
         url: null,
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Manager,
            UserRolesEnum.NationalOrganization,
            UserRolesEnum.InternationalOrganization,
            UserRolesEnum.Staff
         ]
      },
      {
         id: "guided-assessment",
         title: "Guided Assessment",
         isDisabled: false,
         type: "dynamic_list",
         kind: "guided-assessment",
         api: "/assesment/guided/getAll/",
         icon: "flaticon-interface-4",
         url: null,
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Manager,
            UserRolesEnum.NationalOrganization,
            UserRolesEnum.InternationalOrganization,
            UserRolesEnum.Staff,
            UserRolesEnum.Consultant
         ]
      },
      //new menu request for guided assessment
      //if you want to use imported svg you should add iconF and change icon property
      {
         id: "guided-assessment-list",
         title: "Request for Guided Assessment",
         isDisabled: false,
         type: "dynamic_list",
         kind: "guided-assessment-list",
         api: "/assesment/guided/getAll/",
         icon: "RequestForAssessmentIcon.svg",
         iconF: true,
         url: null,
         role: [
            UserRolesEnum.NationalOrganization,
            UserRolesEnum.InternationalOrganization,
            UserRolesEnum.Staff
         ]
      },
      {
         id: "organization-management",
         title: "Organization Management",
         type: "module",
         icon: "flaticon2-layers-1",
         url: "/organization-management",
         role: [
            UserRolesEnum.InternationalOrganization,
            UserRolesEnum.NationalOrganization,
            UserRolesEnum.Staff,
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Consultant,
            UserRolesEnum.Donor,
            UserRolesEnum.Manager
         ],
         subMenus: [
            {
               id: "organization-management-divider-1",
               title: "Organization Management",
               type: "divider",
               role: [
                  UserRolesEnum.InternationalOrganization,
                  UserRolesEnum.NationalOrganization,
                  UserRolesEnum.Staff,
                  UserRolesEnum.SuperAdmin,
                  UserRolesEnum.Consultant,
                  UserRolesEnum.Donor,
                  UserRolesEnum.Manager
               ]
            },
            {
               id: "organization-management-page-1",
               title: "Organization List",
               type: "item",
               icon: "flaticon-layers",
               url: "/organization-management/list",
               role: [
                  UserRolesEnum.InternationalOrganization,
                  UserRolesEnum.NationalOrganization,
                  UserRolesEnum.Staff,
                  UserRolesEnum.SuperAdmin,
                  UserRolesEnum.Consultant,
                  UserRolesEnum.Donor,
                  UserRolesEnum.Manager
               ]
            }
         ]
      },
      {
         id: "help-desk",
         title: "Help desk",
         type: "item",
         icon: "flaticon2-layers-1",
         url: "/help-desk/list",
         role: [
            UserRolesEnum.InternationalOrganization,
            UserRolesEnum.NationalOrganization,
            UserRolesEnum.Staff,
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Consultant,
            UserRolesEnum.Donor,
            UserRolesEnum.Manager
         ]
      },

      {
         id: "teams",
         title: "Experts",
         type: "module",
         icon: "flaticon-users",
         url: "/teams",
         role: [
            UserRolesEnum.InternationalOrganization,
            UserRolesEnum.NationalOrganization,
            UserRolesEnum.Staff
         ],
         subMenus: [
            {
               id: "teams-divider-1",
               title: "Experts",
               type: "divider",
               role: [
                  UserRolesEnum.InternationalOrganization,
                  UserRolesEnum.NationalOrganization,
                  UserRolesEnum.Staff
               ]
            },
            {
               id: "teams-page-1",
               title: "Experts",
               type: "item",
               icon: "flaticon-users",
               url: "/teams/members",
               role: [
                  UserRolesEnum.InternationalOrganization,
                  UserRolesEnum.NationalOrganization,
                  UserRolesEnum.Staff
               ]
            },
            {
               id: "teams-page-2",
               title: "Add member",
               type: "item",
               icon: "flaticon2-plus",
               url: "/teams/add-member",
               role: [UserRolesEnum.InternationalOrganization, UserRolesEnum.NationalOrganization]
            }
         ]
      },
      {
         id: "results",
         title: "Results",
         isDisabled: false,
         type: "dynamic_list",
         kind: "organization-list",
         api: "/api/test/123",
         icon: "flaticon2-pie-chart",
         url: "/results",
         redirect: true,
         role: [
            UserRolesEnum.InternationalOrganization,
            UserRolesEnum.NationalOrganization,
            UserRolesEnum.Manager,
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Donor,
            UserRolesEnum.Staff
         ], // ? Donor won't see my result page ?,
         subMenus: [
            {
               id: "results-page-1",
               title: "Results",
               type: "item",
               icon: "flaticon2-plus",
               url: "/results/",
               role: [
                  UserRolesEnum.InternationalOrganization,
                  UserRolesEnum.NationalOrganization,
                  UserRolesEnum.Manager,
                  UserRolesEnum.SuperAdmin,
                  UserRolesEnum.Donor,
                  UserRolesEnum.Staff
               ]
            }
         ]
      },
      {
         id: "action-plan",
         title: "Action Plan",
         type: "module",
         icon: "flaticon-profile",
         url: "/action-plan",
         role: [
            UserRolesEnum.NationalOrganization,
            UserRolesEnum.InternationalOrganization,
            UserRolesEnum.Staff
         ],
         subMenus: [
            {
               id: "action-plan-divider-1",
               title: "Action Plan Management",
               type: "divider",
               role: [
                  UserRolesEnum.NationalOrganization,
                  UserRolesEnum.InternationalOrganization,
                  UserRolesEnum.Staff
               ]
            },
            {
               id: "action-plan-page-1",
               title: "Action Plan",
               type: "item",
               icon: "flaticon-layers",
               url: "/action-plan/list",
               role: [
                  UserRolesEnum.NationalOrganization,
                  UserRolesEnum.InternationalOrganization,
                  UserRolesEnum.Staff
               ]
            }
         ]
      },
      {
         id: "admin-management",
         title: "Survey Builder",
         type: "null",
         icon: "flaticon2-browser-2",

         url: "/admin-management",
         role: [UserRolesEnum.SuperAdmin, UserRolesEnum.Manager],
         subMenus: [
            {
               id: "assesment-list-d-1",
               title: "ASSESSMENT MANAGEMENT",
               type: "divider",
               role: [UserRolesEnum.SuperAdmin, UserRolesEnum.Manager]
            },
            {
               id: "assesment-list-page-1",
               title: "Assessment list",
               type: "item",
               icon: "flaticon2-notepad",
               url: "/admin-management/assessment/assessment-list",
               role: [UserRolesEnum.SuperAdmin, UserRolesEnum.Manager]
            },
            {
               id: "assesment-list-page-3",
               title: "Assessment requests",
               type: "item",
               icon: "flaticon2-hourglass",
               url: "/admin-management/assessment/assessment-request",
               role: [UserRolesEnum.SuperAdmin, UserRolesEnum.Manager]
            },
            {
               id: "survey-builder-d-1",
               title: "SURVEY MANAGEMENT",
               type: "divider",
               role: [UserRolesEnum.SuperAdmin, UserRolesEnum.Manager]
            },
            {
               id: "survey-builder-page-1",
               title: "Survey list",
               type: "item",
               icon: "flaticon2-notepad",
               url: "/admin-management/survey/all",
               role: [UserRolesEnum.SuperAdmin, UserRolesEnum.Manager]
            },
            {
               id: "survey-builder-d-2",
               title: "Workspace management",
               type: "divider",
               role: [UserRolesEnum.SuperAdmin, UserRolesEnum.Manager]
            },
            {
               id: "survey-builder-page-5",
               title: "Manage workspace consultants",
               type: "item",
               icon: "flaticon2-user",
               url: "/admin-management/workspace/workspace-consultants/",
               role: [UserRolesEnum.SuperAdmin, UserRolesEnum.Manager]
            },
            {
               id: "survey-builder-page-6",
               title: "Manage workspace",
               type: "item",
               icon: "flaticon2-user",
               url: "/admin-management/workspace/workspace-members/",
               role: [UserRolesEnum.SuperAdmin, UserRolesEnum.Manager]
            }
         ]
      }
   ],
   adminLayout: [
      {
         id: "admin_page_01234",
         title: "Experts",
         type: "module",
         icons: { active: GroupPeople, inactive: GroupPeopleInactive },
         url: "/admin/teams",
         role: [UserRolesEnum.SuperAdmin],
         subMenus: []
      },
      {
         id: "admin_page_0123344",
         title: "Assessments",
         type: "module",
         icons: { active: AssessmentsActive, inactive: Assessments },
         url: "/admin/assessment",
         role: [UserRolesEnum.SuperAdmin],
         subMenus: []
      },
      {
         id: "admin_page_0122",
         title: "Surveys",
         type: "module",
         icons: { active: DocumentHandHeldWritingActive, inactive: DocumentHandHeldWriting },
         url: "/admin/survey",
         role: [UserRolesEnum.SuperAdmin],
         subMenus: []
      },
      {
         id: "admin_page_011",
         title: "Workspaces",
         type: "module",
         icons: { active: DashboardWorkspaceActive, inactive: DashboardWorkspace },
         url: "/admin/workspace",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.InternationalOrganization,
            UserRolesEnum.NationalOrganization,
            UserRolesEnum.Staff
         ],
         subMenus: []
      },
      {
         id: "admin_page_01211233",
         title: "Requests",
         type: "module",
         icons: { active: RequestsActive, inactive: Requests },
         url: "/admin/request",
         role: [UserRolesEnum.SuperAdmin],
         subMenus: []
      },
      // {
      //    id: "admin_page_01211234",
      //    title: "Assessment request for guide",
      //    type: "module",
      //    icon: "flaticon2-bell",
      //    url: "/admin/guide",
      //    role: [UserRolesEnum.SuperAdmin],
      //    subMenus: []
      // },
      {
         id: "admin_page_0123435",
         title: "Organizations",
         type: "module",
         icons: { active: OrganizationsActive, inactive: Organizations },
         url: "/admin/organization",
         role: [UserRolesEnum.SuperAdmin],
         subMenus: []
      }

      // {
      //    id: "admin_page_01233",
      //    title: "Countries list",
      //    type: "module",
      //    icon: "flaticon2-hospital",
      //    url: "/admin/country",
      //    role: [UserRolesEnum.SuperAdmin],
      //    subMenus: []
      // }

      // { examples for menus with children
      //   id: "admin_page_02222",
      //   title: "Divider 1",
      //   type: "divider",
      //   role: [UserRolesEnum.SuperAdmin],
      // },
      // {
      //   id: "admin_page_033432",
      //   title: "Survey",
      //   type: "module",
      //   icon: "flaticon2-checking",
      //   url: "/admin/page2",
      //   role: [UserRolesEnum.SuperAdmin],
      //   subMenus: [],
      // },
      // {
      //   id: "Requests",
      //   title: "Requeset",
      //   type: "module",
      //   icon: "flaticon2-checking",
      //   url: "/admin/module1/",
      //   role: [UserRolesEnum.SuperAdmin],
      //   subMenus: [
      //     {
      //       id: "single-example-chld1",
      //       title: "A weird child",
      //       type: "item",
      //       icon: "pie-chart",
      //       url: "/admin/module1/page3",
      //       role: [UserRolesEnum.SuperAdmin],
      //     },
      //     {
      //       id: "single-example-chld311",
      //       title: "Childy Parent",
      //       type: "item",
      //       icon: "pie-chart",
      //       url: "/admin/module1/module2",
      //       role: [UserRolesEnum.SuperAdmin],
      //       children: [
      //         {
      //           id: "child-1",
      //           title: "Child 1-1",
      //           type: "item",
      //           icon: "file",
      //           url: "/admin/module1/module2/page4",
      //           role: [UserRolesEnum.SuperAdmin],
      //         },
      //       ],
      //     },
      //   ],
      // },
   ]
};
