import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss";

import { BackIcon, DonorsLogo, HumanitarianLogo, KaporgIcon, NgoLogo } from "../../assets/icons";
import ProfileOption from "./components/ProfileOption";

function Registration() {
   const navigate = useNavigate();
   const [selectedProfile, setSelectedProfile] = useState(null);

   const handleProfileSelection = (profile) => {
      setSelectedProfile((prevProfile) => (prevProfile === profile ? null : profile));
   };

   const handleSubmit = () => {
      if (selectedProfile) {
         switch (selectedProfile) {
            case "Org":
               navigate("/auth/national-org-registration");
               break;
            case "Vendors":
               navigate("/auth/vendor-registration");
               break;
            case "Donors":
               navigate("/auth/international-donor-registration");
               break;
            case "Expert":
               navigate("/auth/expert-registration");
               break;
            default:
               console.error("Invalid profile selected");
               break;
         }
      }
   };

   return (
      <div style={{ backgroundColor: "#F5F9FC" }}>
         <div className="d-flex flex-column" style={{ padding: "40px" }}>
            <Link to="/">
               <img src={KaporgIcon} alt="" width={106} height={46} />
            </Link>
            <Link
               to="/"
               className="d-flex align-items-center"
               style={{
                  color: "#5492FF",
                  margin: "50px 0 0 16px",
                  fontSize: "16px",
                  fontWeight: 500
               }}
            >
               <img src={BackIcon} alt="Go back" style={{ marginRight: "10px" }} />
               Go back to login
            </Link>
         </div>
         <div className="d-flex flex-column align-items-center">
            <h2 style={{ color: "#555", fontWeight: 600 }}>Welcome to KAPorg!</h2>
            <h1 style={{ color: "#4158CF", fontWeight: 600, textAlign: "center" }}>
               Which profile describes you best?
            </h1>
         </div>

         <div className="container" style={{ padding: "100px 50px 50px 50px" }}>
            <div
               className="row"
               style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(255px, 1fr))",
                  gap: "40px",
                  justifyContent: "center",
                  maxWidth: "1400px",
                  marginLeft: "auto",
                  marginRight: "auto"
               }}
            >
               <>
                  <ProfileOption
                     profile="Org"
                     selectedProfile={selectedProfile}
                     onSelect={handleProfileSelection}
                     image={NgoLogo}
                  />
                  {/* <ProfileOption
                     profile="Vendors"
                     selectedProfile={selectedProfile}
                     onSelect={handleProfileSelection}
                     image={VendorsLogo}
                  /> */}
                  <ProfileOption
                     profile="Donors"
                     selectedProfile={selectedProfile}
                     onSelect={handleProfileSelection}
                     image={DonorsLogo}
                  />
                  <ProfileOption
                     profile="Expert"
                     selectedProfile={selectedProfile}
                     onSelect={handleProfileSelection}
                     image={HumanitarianLogo}
                  />
               </>
            </div>
            <div className="d-flex justify-content-center" style={{ marginTop: "130px" }}>
               <button onClick={handleSubmit} className="signup-btn">
                  Continue
               </button>
            </div>
         </div>
      </div>
   );
}

export default injectIntl(Registration);
