import React, { createContext, useState, useEffect } from "react";
import AuthService from "../../../../../app/base/services/authentication.service";
import { httpService } from "../../../../../app/base/services/httpService.service";

const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
   const [notifications, setNotifications] = useState([]);
   const [unreadCount, setUnreadCount] = useState(0);
   const [user, setUser] = useState(AuthService.getUser());
   const [prevNotificationCount, setPrevNotificationCount] = useState(0);

   const getNotifications = async () => {
      try {
         await httpService.get(`/Notifications/all/${user?.id}`, (res) => {
            const sortedNotifications = res.data.sort((a, b) => {
               if (a.isRead !== b.isRead) {
                  return a.isRead ? 1 : -1;
               }
               return new Date(b.createdDate) - new Date(a.createdDate);
            });
            setNotifications(sortedNotifications);
            const newUnreadNotifications = sortedNotifications.filter(
               (notification) => !notification.isRead
            );
            const newUnreadCount = newUnreadNotifications.length;
            setUnreadCount(newUnreadCount);

         });
      } catch (error) {
         console.error(error);
      }
   };

   useEffect(() => {
      if (user) {
         getNotifications();
      } else {
         setNotifications([]);
         setUnreadCount(0);
         setPrevNotificationCount(0);
      }
   }, [user]);

   const handleNewNotifications = () => {
      getNotifications();
   };

   const markNotificationAsRead = async (notificationId) => {
      try {
         await httpService.post("/Notifications/read", { id: notificationId });
         setNotifications((prevNotifications) =>
            prevNotifications.map((notification) =>
               notification.id === notificationId ? { ...notification, isRead: true } : notification
            )
         );
         setUnreadCount((prevCount) => Math.max(0, prevCount - 1));
      } catch (error) {
         console.error(error);
      }
   };

   const markAllNotificationsAsRead = async () => {
      try {
         await httpService.post("/Notifications/read/all", { userId: user.id });
         setNotifications((prevNotifications) =>
            prevNotifications.map((notification) => ({ ...notification, isRead: true }))
         );
         setUnreadCount(0);
      } catch (error) {
         console.log(error);
      }
   };

   const value = {
      notifications,
      unreadCount,
      markNotificationAsRead,
      markAllNotificationsAsRead,
      setUser
   };

   return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export { NotificationContext, NotificationProvider };
