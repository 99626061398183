// import { Checkbox } from "antd";
import React from "react";
import { Radio } from "antd";

const ProfileOption = ({ profile, selectedProfile, onSelect, image }) => {
   // const [isProfileSelected, setIsProfileSelected] = useState(false);

   const handleProfileChecked = () => {
      // setIsProfileSelected(true);
      onSelect(profile);
   };

   return (
      <div
         onClick={handleProfileChecked}
         className="col-sm d-flex flex-column"
         style={{
            position: "relative",
            border: "1.354px solid #5492FF",
            borderRadius: "14.44px",
            background:
               selectedProfile === profile
                  ? "var(--Gradient-Blueberry, linear-gradient(180deg, #5492FF 0%, #A9C8FF 100%)"
                  : "#fff",
            width: "255px",
            height: "315px",
            boxShadow: "0px 4px 15px 0px rgba(59, 75, 104, 0.10)",
            cursor: "pointer",
            margin: "auto",
            zIndex: 1
         }}
      >
         <div
            className="d-flex align-items-center justify-content-between"
            style={{ padding: "26px 0px 10px 5px" }}
         >
            <h3 style={{ m: 0, color: selectedProfile === profile ? "#fff" : "#4158CF" }}>
               {profile}
            </h3>
            <Radio checked={selectedProfile === profile} />
         </div>
         <div
            style={{
               position: "absolute",
               // top: profile === "Humanitarian" && "-42px",
               // bottom: profile !== "Humanitarian" && "0"
               bottom:
                  (profile === "Org" && "-1px") ||
                  (profile === "Vendors" && "-10px") ||
                  (profile === "Donors" && "-1px"),
               top: profile === "Expert" && "1px"
            }}
         >
            <img
               src={image}
               alt={profile}
               className="overlay-image"
               style={{
                  objectFit: "cover",
                  width:
                     (profile === "Org" && "304px") ||
                     (profile === "Vendors" && "320px") ||
                     (profile === "Donors" && "352px") ||
                     (profile === "Expert" && "300.5px"),
                  marginLeft:
                     (profile === "Org" && "-45px") ||
                     (profile === "Vendors" && "-42px") ||
                     (profile === "Donors" && "-53px") ||
                     (profile === "Expert" && "-12px")
               }}
            />
         </div>
      </div>
   );
};

export default ProfileOption;
