import React from "react";
import { Link } from "react-router-dom";
import { EmailConfirmationIcon } from "../../../assets/icons";

function FinalStepNgo() {
   return (
      <div
         className="pt-md-15 pr-md-10 pb-md-15 pl-md-10 custom-div"
         style={{
            margin: "0 !important",
            padding: "30px 20px",
            height: "auto",
            backgroundColor: "#fff"
         }}
      >
         <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <div className="d-flex justify-content-center">
               <img src={EmailConfirmationIcon} alt="email confirmation icon" />
            </div>
            <div className="d-flex flex-column align-items-center">
               <p
                  style={{
                     color: "#4158CF",
                     fontWeight: 600,
                     fontSize: "26px",
                     textAlign: "center",
                     margin: "0 0 8px "
                  }}
               >
                  We've got your application!
               </p>
               <div
                  className="d-flex flex-column align-items-center"
                  style={{ alignSelf: "stretch", gap: "30px", textAlign: "center" }}
               >
                  <p style={{ color: "#555", fontSize: "16px", margin: "0px" }}>
                     You will receive a confirmation email shortly. In the meantime, check out{" "}
                     <span> www.kaporg.com </span>
                     for more info.
                  </p>
                  <p style={{ color: "#555", fontSize: "16px", fontWeight: 700 }}>
                     Thank you for your interest in KAPorg!
                  </p>
               </div>
            </div>

            <div className="form-group d-flex flex-wrap flex-center">
               <Link to="/">
                  <button type="button" className={`btn sign-btn`} style={{ fontWeight: 500 }}>
                     Got it
                  </button>
               </Link>
            </div>
         </div>
      </div>
   );
}

export default FinalStepNgo;
