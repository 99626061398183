export const getPercentage = (firstNumber, wholeNumber) => {
   return ((firstNumber / wholeNumber) * 100).toFixed(2);
};

export const getSurveyValuesWithPercentages = (chartData) => {
   const percentagesArr = [];
   chartData.organizationScorePerSurveys.forEach((org) => {
      const orgPercentages = {
         name: org.organizationName,
         data: []
      };

      chartData.selectedSurveysList.forEach((survey, index) => {
         const maxScore = survey.maxScore;
         const percentages = getPercentage(org.orgScores[index], maxScore);
         orgPercentages.data.push(percentages);
      });
      percentagesArr.push(orgPercentages);
   });
   return percentagesArr;
};
