import { createSlice } from "@reduxjs/toolkit";
import Toast from "../../base/components/Toast";
import AuthService from "../../base/services/authentication.service";
import https from "../../base/utils/http";
import { httpService } from "../../base/services/httpService.service";
import { LocalStorageService } from "../../base/services/local-storage.service";

const initialState = {
   isLoading: false,
   isError: false,
   errors: [],
   user: null,
   navigateToNgo: null,
   navigateToHumanitarian: null
};
const authSlice = createSlice({
   name: "auth",
   initialState,
   reducers: {
      setLoading(state, { payload }) {
         return {
            ...state,
            isLoading: payload
         };
      },
      setIsError(state, { payload }) {
         state.isError = payload;
      },
      setErrors(state, { payload }) {
         state.errors = payload;
      },
      setAuth(state, { payload }) {
         state.trailerVideos = payload;
      },
      setUser(state, { payload }) {
         state.user = payload;
      },
      setNavigateToNgo(state, { payload }) {
         state.navigateToNgo = payload;
      },
      setNavigateToHumanitarian(state, { payload }) {
         state.navigateToHumanitarian = payload;
      }
   }
});

export const {
   setLoading,
   setIsError,
   setErrors,
   setAuth,
   setUser,
   setNavigateToNgo,
   setNavigateToHumanitarian
} = authSlice.actions;

function clearHTTPS() {
   setIsError(false);
   setErrors(null);
}

export function login(payload, successCallback) {
   clearHTTPS();
   return async (dispatch, getState) => {
      try {
         dispatch(setLoading(true));
         const response = await https.post("/accounts/login", {
            email: payload.email,
            password: payload.password
         });

         if (response.data.success) {
            const userData = response.data.data;
            dispatch(setUser(userData));
            AuthService.setUser(userData);
            AuthService.setTokens(userData.token, userData.refreshToken);
            successCallback();
         }
      } catch (error) {
         if (
            error.response &&
            error.response.status === 400 &&
            error.response.data.errors &&
            error.response.data.errors.some((error) => error.code === "email_not_confirmed")
         ) {
            const emailForNavigation = payload.email;
            try {
               const response = await generateEmailConfirmationCode(emailForNavigation);
               const { role } = response.data;
               if (role === "National Organization") {
                  window.location.href = `/auth/national-org-registration/verification?email=${encodeURIComponent(
                     emailForNavigation
                  )}`;
               } else if (role === "Consultant") {
                  window.location.href = `/auth/confirm-code?email=${encodeURIComponent(
                     emailForNavigation
                  )}`;
               }
            } catch (err) {
               console.error("Error generating email confirmation code:", err);
            }
         } else if (
            error.response &&
            error.response.status === 400 &&
            error.response.data.errors &&
            error.response.data.errors.some((error) => error.code === "profile_not_completed")
         ) {
            const emailForNavigation = payload.email;
            dispatch(setNavigateToHumanitarian({ email: emailForNavigation }));
         } else if (error.response && error.response.data && error.response.data.errors) {
            const errorMessages = error.response.data.errors.map((error) => error.message);
            errorMessages.forEach((errorMessage) => {
               Toast.error(errorMessage);
            });
         } else {
            Toast.error("An unexpected error occurred. Please try again.");
         }
      } finally {
         dispatch(setLoading(false));
      }
   };
}

// export function refreshToken(rtoken) {
//    clearHTTPS();
//    return async (dispatch, getState) => {
//       try {
//          dispatch(setLoading(true));
//          const response = await https.post("/accounts/refresh-token", {
//             refreshToken: rtoken
//          });
//          console.log("HELLO: ",response)

//          if (response.data.success) {
//             const userData = response.data.data;
//             dispatch(setUser(userData));
//             AuthService.setUser(userData);
//             AuthService.setTokens(userData.token, userData.refreshToken);
//          }
//       } catch (error) {
//          Toast.error("An unexpected error occurred. Please try again.");
//       } finally {
//          dispatch(setLoading(false));
//       }
//    };
// }

const generateEmailConfirmationCode = async (email) => {
   try {
      const response = await https.post("/Accounts/generate/email-confirmation-code", {
         email: email
      });
      return response.data;
   } catch (error) {
      console.error("Error generating email confirmation code:", error);
      throw error;
   }
};

export function createUser(payload, successCallback) {
   clearHTTPS();
   return async (dispatch, getState) => {
      await dispatch(setLoading(true));
      https
         .post("/account/createuser", {
            email: payload.email,
            password: payload.password,
            fullName: payload.fullName
         })
         .then((response) => {
            if (response.data.success) {
               dispatch(setUser({ id: response.data.data.id }));

               successCallback(response.data.data);
               Toast.success("A verification code has been sent to your email.");
            }
         })
         .finally(() => {
            dispatch(setLoading(false));
         });
   };
}

export function registerVendor(payload, successCallback) {
   clearHTTPS();
   return async (dispatch, getState) => {
      await dispatch(setLoading(true));
      https
         .post("/Accounts/register/consultant", payload)
         .then((response) => {
            if (response.data.success) {
               dispatch(setUser({ id: response.data.data.userId }));
               successCallback(response.data.data);
               Toast.success("A verification code has been sent to your email.");
            }
         })
         .finally(() => {
            dispatch(setLoading(false));
         });
   };
}

export function registerUserNgo(payload, successCallback) {
   clearHTTPS();
   return async (dispatch, getState) => {
      try {
         dispatch(setLoading(true));
         const response = await https.post("/Accounts/register/ngo", payload);
         if (response.data.success) {
            const userId = response.data.data.id;
            dispatch(setUser({ id: userId }));
            LocalStorageService.setItem("userId", userId);
            successCallback(response.data.data);
            dispatch(setLoading(false));
            Toast.success("A verification code has been sent to your email.");
         }
      } catch (error) {
         if (error.response && error.response.data && error.response.data.errors) {
            const errorMessages = error.response.data.errors.map((error) => error.message);
            if (errorMessages.length > 0) {
               errorMessages.forEach((errorMessage) => {
                  Toast.error(errorMessage);
               });
            }
         } else {
            Toast.error("An unexpected error occurred. Please try again.");
         }
      } finally {
         dispatch(setLoading(false));
      }
   };
}

export function registerDonor(payload, successCallback) {
   clearHTTPS();
   return async (dispatch, getState) => {
      try {
         dispatch(setLoading(true));
         const response = await https.post("/Accounts/register/donor", payload);
         if (response.data.success) {
            successCallback(response.data.data);
            dispatch(setLoading(false));
            Toast.success("Account created successfully.");
         }
      } catch (error) {
         if (error.response && error.response.data && error.response.data.errors) {
            const errorMessages = error.response.data.errors.map((error) => error.message);
            if (errorMessages.length > 0) {
               errorMessages.forEach((errorMessage) => {
                  Toast.error(errorMessage);
               });
            }
         } else {
            Toast.error("An unexpected error occurred. Please try again.");
         }
      } finally {
         dispatch(setLoading(false));
      }
   };
}

export function registerHumanitarianUser(payload, successCallback) {
   clearHTTPS();
   return async (dispatch, getState) => {
      try {
         dispatch(setLoading(true));
         const response = await https.post("Accounts/register/consultant", payload);

         if (response.data.success) {
            dispatch(setUser({ id: response.data.data.userId }));
            const userId = response.data.data.id;
            LocalStorageService.setItem("userId", userId);
            successCallback(response.data.data);
            dispatch(setLoading(false));
            Toast.success("A verification code has been sent to your email.");
         }
      } catch (error) {
         if (error.response && error.response.data && error.response.data.errors) {
            const errorMessages = error.response.data.errors.map((error) => error.message);
            if (errorMessages.length > 0) {
               errorMessages.forEach((errorMessage) => {
                  Toast.error(errorMessage);
               });
            }
         } else {
            Toast.error("An unexpected error occurred. Please try again.");
         }
      } finally {
         dispatch(setLoading(false));
      }
   };
}

export const completeConsultantProfile = (userId, payload, successCallback) => {
   return async (dispatch) => {
      await dispatch(setLoading(true));
      try {
         const formData = new FormData();
         Object.keys(payload).forEach((key) => {
            if (key === "Expertises" || key === "Languages") {
               payload[key].forEach((item, index) => {
                  Object.keys(item).forEach((itemKey) => {
                     formData.append(`${key}[${index}].${itemKey}`, item[itemKey]);
                  });
               });
            } else if (key === "OperatingCountriesIds") {
               payload[key].forEach((item, index) => {
                  formData.append(`${key}[${index}]`, item);
               });
            } else {
               formData.append(key, payload[key]);
            }
         });

         await httpService.post(
            `/Accounts/register/${userId}/consultant/complete-profile`,
            formData,
            (data) => {
               LocalStorageService.removeItem("userId");
               LocalStorageService.removeItem("consultantProfileData");

               Toast.success("Expert user created successfully");
               successCallback(data);
            },
            (data) => {
               console.error("API error:", data);
            }
         );
      } finally {
         dispatch(setLoading(false));
      }
   };
};

export function validateConfirmationCode(code, successCallback, redirectedID) {
   return async (dispatch, getState) => {
      const id = getState()?.auth?.user?.id;

      await dispatch(setLoading(true));
      httpService
         .post(
            "/account/validateconfirmationcode",
            {
               userId: redirectedID ? redirectedID : id,
               code: code
            },
            () => {
               Toast.success("Please fill the organization information!");
               successCallback();
            }
         )
         .finally(() => {
            dispatch(setLoading(false));
         });
   };
}

export const getAuth = (state) => state.auth.auth;

export default authSlice.reducer;
