import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Icon } from "react-icons-kit";
import { mail } from "react-icons-kit/feather/mail";
import { clock } from "react-icons-kit/feather/clock";
import { map } from "react-icons-kit/feather/map";
import { book } from "react-icons-kit/feather/book";
import { search } from "react-icons-kit/feather/search";
import { checkIsActive } from "../../../../../_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getResultFilters } from "../../../../../../app/pages/workspace/results/selectors";
import { useSelector } from "react-redux";
import { setFilterOrganizations } from "../../../../../../app/pages/workspace/results/resultSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import Toast from "../../../../../../app/base/components/Toast";
import { getPercentage } from "../../../../../../app/pages/workspace/results/helpers";

function MenuOrganizationList({ menuItem }) {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const location = useLocation();
   const [isSelected, setIsSelected] = useState(false);
   const [compareOverallScores, setCompareOverallScores] = useState([]);
   const [selectedOrganizationIds, setSelectedOrganizationIds] = useState([])
   const getMenuItemActive = (url, hasSubmenu = false) => {
      return checkIsActive(location, url)
         ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
         : "";
   };

   const { filterData } = useSelector((state) => ({
      filterData: getResultFilters(state)
   }));

   const selectOrganization = (orgId) => {
      let selectedOrganizations = [...filterData.organizationsIdList];
      setSelectedOrganizationIds(...selectedOrganizationIds, selectedOrganizations)
      console.log("selectedorganizations::", selectedOrganizations)
      if (isSelected) {
         let index = selectedOrganizations.indexOf(orgId);
         if (index !== -1) {
            selectedOrganizations.splice(index, 1);
            dispatch(setFilterOrganizations(selectedOrganizations));
         }
         setIsSelected(false);
      } else {
         if (selectedOrganizations.length === 5) {
            Toast.warning("Maximum 5 organizations!");
         } else {
            dispatch(setFilterOrganizations([...filterData.organizationsIdList, orgId]));
            setIsSelected(true);
         }
      }

      navigate("/results/compareResults");
   };

   useEffect(() => {
      console.log("selectedOrganizationIds ddd", selectedOrganizationIds)
   }, [selectedOrganizationIds])

   
   useEffect(() => {
      const selectedOrganizations = filterData.organizationsIdList;
      const thisOrgId = menuItem.id;

      if (selectedOrganizations.includes(thisOrgId)) {
         setIsSelected(true);
      } else {
         setIsSelected(false);
      }
   }, [filterData]);

   return (
      <li
         key={menuItem.id}
         className={`menu-item organization-item ${getMenuItemActive(
            menuItem.url,
            menuItem.children?.length > 0 ? true : false
         )}`}
         aria-haspopup="true"
         data-menu-toggle="hover"
      >
         <div onClick={() => selectOrganization(menuItem.id)} className="result-list">
            <div className="select-circle">
               {isSelected ? (
                  <svg
                     width="16"
                     height="15"
                     viewBox="0 0 16 15"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        d="M15.3907 7.5C15.3907 11.3617 12.2303 14.5 8.32229 14.5C4.41424 14.5 1.25391 11.3617 1.25391 7.5C1.25391 3.63828 4.41424 0.5 8.32229 0.5C12.2303 0.5 15.3907 3.63828 15.3907 7.5Z"
                        stroke="#40F372"
                     />
                     <ellipse cx="8.32228" cy="7.5" rx="4.54103" ry="4.5" fill="#40F372" />
                  </svg>
               ) : (
                  <svg
                     width="16"
                     height="15"
                     viewBox="0 0 16 15"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        d="M15.3907 7.5C15.3907 11.3617 12.2303 14.5 8.32229 14.5C4.41424 14.5 1.25391 11.3617 1.25391 7.5C1.25391 3.63828 4.41424 0.5 8.32229 0.5C12.2303 0.5 15.3907 3.63828 15.3907 7.5Z"
                        stroke="#40F372"
                     />
                  </svg>
               )}
            </div>
            <div className="information-part">
               <div className="information-wrapper">
                  <p className="result-title">{menuItem.title}</p>
                  <div className="social-part">
                     <OverlayTrigger
                        key={"email"}
                        placement={"bottom"}
                        overlay={
                           <Tooltip id={`tooltip-${menuItem.id}-tp`}>{menuItem.email}</Tooltip>
                        }
                     >
                        <span className="info-w-icon d-flex">
                           <Icon className="icon" icon={mail} size={10} />
                           <span className="icon-text">{menuItem.email}</span>
                        </span>
                     </OverlayTrigger>

                     <span className="info-w-icon d-flex">
                        <Icon className="icon" icon={clock} size={10} />
                        <span className="icon-text">{menuItem.registrationDate}</span>
                     </span>
                     <OverlayTrigger
                        key={"location"}
                        placement={"bottom"}
                        overlay={
                           <Tooltip id={`tooltip-${menuItem.id}-tp`}>{menuItem.location}</Tooltip>
                        }
                     >
                        <span className="info-w-icon d-flex">
                           <Icon className="icon" icon={map} size={10} />
                           <span className="icon-text">{menuItem.location}</span>
                        </span>
                     </OverlayTrigger>
                  </div>
               </div>
            </div>
            <div className="line"></div>
            <div className="assessment-result-section">
               <span className="info-w-icon d-flex">
                  <Icon icon={book} className="icon" size={10} />
                  <span className="icon-text">
                     Score{" "}
                     {menuItem.selfAssessmentScore
                        ? getPercentage(
                             menuItem.selfAssessmentScore,
                             menuItem.selfAssessmentMaxScore
                          )
                        : "N/A"}
                     %
                  </span>
               </span>
               <span className="info-w-icon d-flex">
                  <Icon icon={search} className="icon" size={10} />
                  <span className="icon-text">
                     {menuItem.paidAssessmentScore
                        ? getPercentage(
                             menuItem.paidAssessmentScore,
                             menuItem.paidAssessmentMaxScore
                          )
                        : "N/A"}
                  </span>
               </span>
            </div>
         </div>
      </li>
   );
}

export default MenuOrganizationList;
