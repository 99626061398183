import React, { useState } from "react";
import "./style.scss";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Link } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { BackIcon, EyeInvisible } from "../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { registerVendor } from "./authSlice";
import * as Yup from "yup";
import { LocalStorageService } from "../../base/services/local-storage.service";
// import { ReactComponent as WaveSvg } from "../../assets/images/wave-vector-registration.svg";
const initialValues = {
   email: "",
   password: "",
   confirmPassword: "",
   internationalName: "",
   description: "",
   dateOfEstablishment: null,
   orgSizeId: null,
   sectors: [],
   organizationEmail: "",
   baseCountryId: null,
   baseCityId: null,
   address: "",
   zip: ""
   // orgTypeId: 1, //we need to set this for the moment as 1 statically
};

function RegistrationOfVendor() {
   const [type, setType] = useState("password");
   const [icon, setIcon] = useState(eyeOff);

   const [confirmType, setConfirmType] = useState("password");
   const [confirmIcon, setConfirmIcon] = useState(eyeOff);

   const dispatch = useDispatch();

   const validationSchema = Yup.object().shape({
      email: Yup.string()
         .email("Invalid email address")
         .required("Email is required"),
      password: Yup.string()
         .min(8, "Password must be at least 8 characters")
         .required("Password is required"),
      confirmPassword: Yup.string()
         .oneOf([Yup.ref("password"), null], "Passwords must match")
         .required("Confirm Password is required")
   });

   const handleTogglePassword = () => {
      if (type === "password") {
         setIcon(eye);
         setType("text");
      } else {
         setIcon(eyeOff);
         setType("password");
      }
   };

   const handleToggleConfirmPassword = () => {
      if (confirmType === "password") {
         setConfirmIcon(eye);
         setConfirmType("text");
      } else {
         setConfirmIcon(eyeOff);
         setConfirmType("password");
      }
   };

   const onSubmit = async (values) => {
      try {
         console.log("values", values);
         const updatedValues = {
            ...values
         };

         dispatch(
            registerVendor(updatedValues, (user) => {
               console.log("userId", user);
               LocalStorageService.setItem("userId", user.userId);
               // navigate(`/auth/confirm-code`);
            })
         );
      } catch (error) {
         console.error("Error during form submission:", error);
      }
   };

   return (
      <div style={{ padding: "40px 160px 80px" }}>
         <Link
            to="/auth/registration"
            className="d-flex align-items-center"
            style={{
               color: "#5492FF",
               // margin: "50px 0 0 16px",
               fontSize: "16px",
               fontWeight: 500,
               cursor: "pointer"
            }}
         >
            <img src={BackIcon} alt="Go back" style={{ marginRight: "10px" }} />
            Go back to profile selection
         </Link>
         <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
         >
            {({ errors, touched }) => (
               <Form>
                  <div
                     style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "40px",
                        gap: "32px"
                     }}
                  >
                     <p
                        style={{
                           fontSize: "26px",
                           color: "#4158CF",
                           fontWeight: 600,
                           margin: "0px"
                        }}
                     >
                        Create an account <span style={{ color: "#5492FF" }}>as a Vendor</span>
                     </p>

                     <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                        <div className="form-group fv-plugins-icon-container">
                           <label
                              style={{
                                 fontSize: "16px",
                                 color: "#3A3737",
                                 fontWeight: 500,
                                 marginBottom: "10px"
                              }}
                           >
                              <span style={{ color: "red" }}>* </span>
                              Email
                           </label>
                           <Field
                              placeholder="yourmail@gmail.com"
                              className={`form-control`}
                              type="email"
                              name="email"
                              // onChange={handleChange}
                              // values={values.email}
                           />
                           {errors.email && touched.email && (
                              <div className="text-danger">{errors.email}</div>
                           )}
                           {/* {errors.email && <div className="error-message">{errors.email}</div>} */}
                        </div>
                        <div className="form-group fv-plugins-icon-container">
                           <label
                              style={{
                                 fontSize: "16px",
                                 color: "#3A3737",
                                 fontWeight: 500,
                                 marginBottom: "10px"
                              }}
                           >
                              <span style={{ color: "red" }}>* </span>
                              Password
                           </label>
                           {/* <Tooltip title="Min 3 symbols">
                        <Button>?</Button>
                     </Tooltip> */}
                           <div className="password-section">
                              <Field
                                 placeholder="Password"
                                 type={type}
                                 name="password"
                                 className="form-control"
                              />
                              <img
                                 className="eyeButton"
                                 onClick={handleTogglePassword}
                                 src={EyeInvisible}
                                 alt="showPasswordIcon"
                              />
                           </div>

                           {errors.password && touched.password && (
                              <div className="text-danger">{errors.password}</div>
                           )}
                        </div>
                        <div className="form-group fv-plugins-icon-container">
                           <label
                              style={{
                                 fontSize: "16px",
                                 color: "#3A3737",
                                 fontWeight: 500,
                                 marginBottom: "10px"
                              }}
                           >
                              Confirm password
                           </label>
                           {/* <Tooltip title="Min 3 symbols">
                        <Button>?</Button>
                     </Tooltip> */}
                           <div className="password-section">
                              <Field
                                 placeholder="Password"
                                 type={confirmType}
                                 name="confirmPassword"
                                 className="form-control"
                              />
                              <img
                                 className="eyeButton"
                                 onClick={handleToggleConfirmPassword}
                                 src={EyeInvisible}
                                 alt="showPasswordIcon"
                              />
                           </div>

                           {errors.confirmPassword && touched.confirmPassword && (
                              <div className="text-danger">{errors.confirmPassword}</div>
                           )}
                        </div>
                     </div>
                     <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
                        <button
                           id="kt_login_signin_submit"
                           type="submit"
                           className={`btn sign-btn font-weight-bold my-3`}
                        >
                           {/* {loading ? <Spin/> : "Continue"} */}
                           Continue
                           {/* {isLoading && <span className="ml-3 spinner spinner-white"></span>} */}
                        </button>
                     </div>
                  </div>
               </Form>
            )}
         </Formik>
      </div>

      // <div>
      //    <nav className="navbar navbar-ngo">
      //       <div className="container">
      //          <div className="logo-ingo">
      //             <img
      //                src={toAbsoluteUrl("/media/logos/kapOrg-logo.svg")}
      //                width="100"
      //                height="36"
      //                className="d-inline-block d-sm-flex justify-content-sm-center align-items-sm-center"
      //                alt="NGO Logo"
      //             />
      //          </div>
      //          <div className="col-12 col-md-9">
      //             <h2 className="ingo-navbartext">
      //                INGO /&nbsp;
      //                <span className="text-white"> Vendor</span>
      //             </h2>
      //          </div>
      //       </div>
      //    </nav>

      //    <div className="bg-white py-5">
      //       <div className="container d-flex justify-content-around ">
      //          <div className="mt-5 col-12 col-md-6">
      //             <h4 className="sign-up-info">Sign Up Information</h4>

      //             <p className="signup-p">
      //                To create your account, please fill in the following information.
      //             </p>
      //          </div>
      //       </div>
      //    </div>

      //    <div className="bg-form">
      //       <div className="container">
      //          <div className="row justify-content-center">
      //             <div className="col-12 col-md-6 mt-10">
      //                <div className="mt-5">
      //                   <h5 className="ingo-login__cred">Login Credentials</h5>
      //                </div>
      //                <div className="p-8 bg-white mt-15 form-styleNGO">
      //                   <form>
      //                      <div className="form-group">
      //                         <label htmlFor="email">Organization’s Email*</label>
      //                         <input type="email" className="form-control" />
      //                      </div>

      //                      <div className="form-group">
      //                         <label htmlFor="password">Password*</label>
      //                         <input type="password" className="form-control" />
      //                      </div>

      //                      <div className="form-group">
      //                         <label htmlFor="confirmPassword">Confirm Password*</label>
      //                         <input type="password" className="form-control" />
      //                      </div>
      //                      <div className="form-group">
      //                         <label htmlFor="email">Invitation code*</label>
      //                         <input type="text" className="form-control" />
      //                      </div>
      //                   </form>
      //                </div>
      //             </div>
      //          </div>

      //          <div className="container">
      //             <div className="row justify-content-center">
      //                <div className="col-12 col-md-6 my-12 ">
      //                   <div className="d-flex justify-content-between">
      //                      <label className="checkbox">
      //                         <input
      //                            type="checkbox"
      //                            name="acceptTerms"
      //                            required
      //                            className="m-1 terms-conditions-btn"
      //                         />
      //                         <span className="mr-3 checkbox__style" />
      //                         <Link
      //                            to="/terms"
      //                            target="_blank"
      //                            className="mr-1 r-btn ingo-terms-text"
      //                            rel="noopener noreferrer"
      //                         >
      //                            I have read and agreed with the{" "}
      //                            <span> terms and conditions. </span>
      //                         </Link>
      //                      </label>
      //                   </div>
      //                </div>
      //             </div>

      //             <div className="container">
      //                <div className="row justify-content-center">
      //                   <div className="col-12 col-md-6">
      //                      <button type="submit" className="btn ngoBtn">
      //                         <p className="ngoBtn-submit">Submit</p>
      //                      </button>
      //                   </div>
      //                </div>
      //             </div>

      //             {/* <div className="footer">
      //                <WaveSvg className="wave-svgngo" />
      //             </div> */}
      //          </div>
      //       </div>
      //    </div>
      // </div>
   );
}
export default RegistrationOfVendor;
