import axios from "axios";
import AuthService from "../services/authentication.service";
import Toast from "../components/Toast";

const { REACT_APP_BASE_URL, REACT_APP_FILE_MANAGEMENT } = process.env;

const https = axios.create({
   baseURL: REACT_APP_BASE_URL,
   timeout: 60000,
   headers: { "Content-Type": "application/json" }
});

export const setupInterceptor = (store) => {
   https.interceptors.request.use((config) => {
      const token = AuthService.getAccessToken();

      if (token !== null || token !== "") {
         config.headers.Authorization = `Bearer ${token}`;
      }

      config.headers.Authorization = `Bearer ${token}`;
      if (config.url && config.url.startsWith("/FileData/getFileUrlByFileKeyAndEntityId")) {
         config.baseURL = REACT_APP_FILE_MANAGEMENT;
      } else {
         config.baseURL = REACT_APP_BASE_URL;
      }

      return config;
   });

   https.interceptors.response.use(
      (response) => {
         if (!response.data.success) {
            const errMessages = response?.data?.messages?.join("");
            Toast.warning(errMessages);
         }
         return response;
      },
      (error) => {
         if (navigator.onLine) {
            handleException(error);
         } else {
            Toast.warning("Check your internet connection");
         }
         return Promise.reject(error);
      }
   );

   const handleException = (error) => {
      // switch (error.response?.status) {
      //    case 401:
      //       window.location = "/unauthorized";
      //       //todo refresh token alınacak
      //       break;
      //    case 404:
      //       //todo loglama apisine veri gönder
      //       window.location = "/404";
      //       break;
      //    default:
      //       //todo loglama apisine veri gönder
      //       window.location = "/500";
      //       break;
      // }
      return Promise.reject(error);
   };
};

export default https;
