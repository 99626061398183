import { UserRolesEnum } from "./pages/auth/userRoles.enum";
import {
   GroupPeople,
   Assessments,
   DocumentHandHeldWriting,
   DashboardWorkspace,
   Requests,
   Organizations,
   GroupPeopleInactive,
   DocumentHandHeldWritingActive,
   DashboardWorkspaceActive,
   AssessmentsActive,
   RequestsActive,
   OrganizationsActive
} from "./assets/icons";
export const mainMenu = {
   layout: [
      {
         id: "assessments",
         title: "Assessments",
         role: [
            UserRolesEnum.Manager,
            UserRolesEnum.NationalOrganization,
            UserRolesEnum.InternationalOrganization,
            UserRolesEnum.Consultant
         ],
         type: "list",
         children: [
            {
               id: "self-assessments",
               title: "Self assessments",
               type: "route",
               link: "/assesment/self-assessment/",
               kind: "self-assessment",
               api: "/assesment/self-assessment/",
               url: "/workspace/assessment/self-assessment/",
               icon: "selfAssessment.svg",
               role: [
                  UserRolesEnum.Manager,
                  UserRolesEnum.NationalOrganization,
                  UserRolesEnum.InternationalOrganization
               ]
            },
            {
               id: "guided-assessment",
               title: "Guided assessments",
               type: "route",
               link: "/assesment/guided-assessment/",
               kind: "guided-assessment",
               api: "/assesment/guided-assessment/",
               url: "/workspace/assessment/guided-assessment/",
               icon: "guided.svg",
               role: [
                  UserRolesEnum.Manager,
                  UserRolesEnum.NationalOrganization,
                  UserRolesEnum.InternationalOrganization,
                  UserRolesEnum.Consultant
               ]
            }
         ]
      },
      {
         id: "results",
         title: "Results",
         role: [
            UserRolesEnum.InternationalOrganization,
            UserRolesEnum.NationalOrganization,
            UserRolesEnum.Manager,
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Donor,
            UserRolesEnum.Staff,
            UserRolesEnum.Consultant
         ],
         type: "list",
         children: [
            {
               id: "results",
               title: "Results",
               type: "route",
               link: "/api/test/123",
               icon: "results.svg",
               kind: "results",
               api: "/api/test/123",
               url: "/workspace/results/results",
               adminUrl: "/workspace/results/results/organizations",
               redirect: true,
               role: [
                  UserRolesEnum.InternationalOrganization,
                  UserRolesEnum.NationalOrganization,
                  UserRolesEnum.Manager,
                  UserRolesEnum.SuperAdmin,
                  UserRolesEnum.Donor,
                  UserRolesEnum.Consultant
               ],
               adminRole: [
                  UserRolesEnum.SuperAdmin,
                  UserRolesEnum.Donor,
                  UserRolesEnum.InternationalOrganization,
                  UserRolesEnum.Consultant
               ]
            },
            {
               id: "action-plan",
               title: "Action plan",
               type: "route",
               link: "/action-plan",
               api: "/assessment/action-plan/list",
               kind: "action-plan",
               icon: "action.svg",
               url: "/workspace/results/action-plan",
               role: [
                  UserRolesEnum.Manager,
                  UserRolesEnum.NationalOrganization,
                  UserRolesEnum.SuperAdmin,
                  UserRolesEnum.Staff
               ]
            }
         ]
      },
      {
         id: "organizations",
         title: "Organizations",
         role: [
            UserRolesEnum.InternationalOrganization,
            UserRolesEnum.NationalOrganization,
            UserRolesEnum.Manager,
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Donor
         ],
         type: "banner",
         children: [
            {
               id: "organization-management",
               title: "Organization management",
               type: "banner",
               link: "/workspace/organization-management",
               icon: "Illustration.svg",
               role: [
                  UserRolesEnum.InternationalOrganization,
                  UserRolesEnum.NationalOrganization,
                  UserRolesEnum.Manager,
                  UserRolesEnum.SuperAdmin,
                  UserRolesEnum.Donor
               ]
            }
         ]
      }
   ],
   adminLayout: [
      {
         id: "admin_page_01234",
         title: "Team management",
         type: "module",
         icons: { active: GroupPeople, inactive: GroupPeopleInactive },
         url: "/admin/teams",
         role: [UserRolesEnum.SuperAdmin],
         subMenus: []
      },
      {
         id: "admin_page_0123344",
         title: "Assessments",
         type: "module",
         icons: { active: AssessmentsActive, inactive: Assessments },
         url: "/admin/assessment",
         role: [UserRolesEnum.SuperAdmin],
         subMenus: []
      },
      {
         id: "admin_page_0122",
         title: "Surveys",
         type: "module",
         icons: { active: DocumentHandHeldWritingActive, inactive: DocumentHandHeldWriting },
         url: "/admin/survey",
         role: [UserRolesEnum.SuperAdmin],
         subMenus: []
      },
      {
         id: "admin_page_011",
         title: "Workspaces",
         type: "module",
         icons: { active: DashboardWorkspaceActive, inactive: DashboardWorkspace },
         url: "/admin/workspace",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.InternationalOrganization,
            UserRolesEnum.NationalOrganization,
            UserRolesEnum.Staff
         ],
         subMenus: []
      },
      {
         id: "admin_page_01211233",
         title: "Requests",
         type: "module",
         icons: { active: RequestsActive, inactive: Requests },
         url: "/admin/request",
         role: [UserRolesEnum.SuperAdmin],
         subMenus: []
      },
      // {
      //    id: "admin_page_01211234",
      //    title: "Assessment request for guide",
      //    type: "module",
      //    icon: "flaticon2-bell",
      //    url: "/admin/guide",
      //    role: [UserRolesEnum.SuperAdmin],
      //    subMenus: []
      // },
      {
         id: "admin_page_0123435",
         title: "Organizations",
         type: "module",
         icons: { active: OrganizationsActive, inactive: Organizations },
         url: "/admin/organization",
         role: [UserRolesEnum.SuperAdmin],
         subMenus: []
      }

      // {
      //    id: "admin_page_01233",
      //    title: "Countries list",
      //    type: "module",
      //    icon: "flaticon2-hospital",
      //    url: "/admin/country",
      //    role: [UserRolesEnum.SuperAdmin],
      //    subMenus: []
      // }

      // { examples for menus with children
      //   id: "admin_page_02222",
      //   title: "Divider 1",
      //   type: "divider",
      //   role: [UserRolesEnum.SuperAdmin],
      // },
      // {
      //   id: "admin_page_033432",
      //   title: "Survey",
      //   type: "module",
      //   icon: "flaticon2-checking",
      //   url: "/admin/page2",
      //   role: [UserRolesEnum.SuperAdmin],
      //   subMenus: [],
      // },
      // {
      //   id: "Requests",
      //   title: "Requeset",
      //   type: "module",
      //   icon: "flaticon2-checking",
      //   url: "/admin/module1/",
      //   role: [UserRolesEnum.SuperAdmin],
      //   subMenus: [
      //     {
      //       id: "single-example-chld1",
      //       title: "A weird child",
      //       type: "item",
      //       icon: "pie-chart",
      //       url: "/admin/module1/page3",
      //       role: [UserRolesEnum.SuperAdmin],
      //     },
      //     {
      //       id: "single-example-chld311",
      //       title: "Childy Parent",
      //       type: "item",
      //       icon: "pie-chart",
      //       url: "/admin/module1/module2",
      //       role: [UserRolesEnum.SuperAdmin],
      //       children: [
      //         {
      //           id: "child-1",
      //           title: "Child 1-1",
      //           type: "item",
      //           icon: "file",
      //           url: "/admin/module1/module2/page4",
      //           role: [UserRolesEnum.SuperAdmin],
      //         },
      //       ],
      //     },
      //   ],
      // },
   ]
};
