export const UserRolesEnum = {
   SuperAdmin: "fab4fac1-c546-41de-aebc-a14da6895711",
   Staff: "c7b013f0-5201-4317-abd8-c211f91b7330",
   InternationalOrganization: "b74ddd14-6340-4840-95c2-db12554843e5",
   NationalOrganization: "c7b013f0-5201-4317-abd8-c211f91b7332",
   Donor: "b74ddd14-6340-4840-95c2-db12554843e6",
   Manager: "b74ddd14-6340-4840-95c2-db12554843e4", //workspace admin
   Consultant: "b74ddd14-6340-4840-95c2-db12554843e0"
};

export const UserRoles = [
   {
      id: "fab4fac1-c546-41de-aebc-a14da6895711",
      label: "Super Admin"
   },
   {
      id: "c7b013f0-5201-4317-abd8-c211f91b7330",
      label: "Staff"
   },
   {
      id: "b74ddd14-6340-4840-95c2-db12554843e5",
      label: "International Organization"
   },
   {
      id: "c7b013f0-5201-4317-abd8-c211f91b7332",
      label: "National Organization"
   },
   {
      id: "b74ddd14-6340-4840-95c2-db12554843e6",
      label: "Donor"
   },
   {
      id: "b74ddd14-6340-4840-95c2-db12554843e4",
      label: "Manager"
   },
   {
      id: "b74ddd14-6340-4840-95c2-db12554843e0",
      label: "Consultant"
   }
];
