/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Routess } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { ToastContainer } from "react-toastify";
import { NotificationProvider } from "../_metronic/layout/components/subheader/notificationContext/NotificationContext";

export default function App({ store, persistor }) {
   return (
      <NotificationProvider>
         <React.Suspense fallback={<LayoutSplashScreen />}>
            {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <BrowserRouter>
               {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
               <MaterialThemeProvider>
                  {/* Provide `react-intl` context synchronized with Redux state.  */}
                  <I18nProvider>
                     {/* Render routes with provided `Layout`. */}
                     <Routess />
                     <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                     />
                     {/* Same as */}
                     <ToastContainer />
                  </I18nProvider>
               </MaterialThemeProvider>
            </BrowserRouter>
         </React.Suspense>
      </NotificationProvider>
   );
}
