/* eslint-disable jsx-a11y/anchor-is-valid */
import objectPath from "object-path";
import React, { useEffect, useMemo, useState, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import AuthService from "../../../../app/base/services/authentication.service";
import { toAbsoluteUrl } from "../../../_helpers";
import { isDarkMode, toggleDarkMode } from "../../../_helpers/DarkModeHelper";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Brand } from "../brand/Brand";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { AsideMenu } from "./aside-menu/AsideMenu";
import { UserRolesEnum } from "../../../../app/pages/auth/userRoles.enum";

import { mainMenu } from "../../../../app/MainMenu";

export function Aside() {
   const location = useLocation();
   const currentPathID = location.pathname.split("/").slice(1)[0];
   const navigate = useNavigate();
   const isOrganizationManagement = location.pathname.includes("organization-management");
   const isGuidedConsultant =
      location.pathname.includes("guided-assessment") &&
      AuthService.isAuthorized([UserRolesEnum.Consultant]);
   const isStaff = AuthService.isAuthorized([UserRolesEnum.Staff]);
   const isHelpDesk =
      location.pathname.includes("help-desk/list") ||
      location.pathname.includes("help-desk/details");

   const menuItemRef = useRef(null);
   const user = AuthService.getUser();
   const uiService = useHtmlClassService();

   const layoutProps = useMemo(() => {
      return {
         asideClassesFromConfig: uiService.getClasses("aside", true),
         asideSecondaryDisplay: objectPath.get(uiService.config, "aside.secondary.display"),
         asideSelfMinimizeToggle: objectPath.get(uiService.config, "aside.self.minimize.toggle"),
         extrasSearchDisplay: objectPath.get(uiService.config, "extras.search.display"),
         extrasNotificationsDisplay: objectPath.get(
            uiService.config,
            "extras.notifications.display"
         ),
         extrasQuickActionsDisplay: objectPath.get(
            uiService.config,
            "extras.quick-actions.display"
         ),
         extrasQuickPanelDisplay: objectPath.get(uiService.config, "extras.quick-panel.display"),
         extrasLanguagesDisplay: objectPath.get(uiService.config, "extras.languages.display"),
         extrasUserDisplay: objectPath.get(uiService.config, "extras.user.display")
      };
   }, [uiService]);

   const [activeTab, setActiveTab] = useState(() => {
      return currentPathID;
   });

   const [isDarkModeOn, setisDarkModeOn] = useState(isDarkMode());

   useEffect(() => {
      setActiveTab(currentPathID);
   }, [currentPathID]);

   const handleTabChange = (id) => {
      let newId = id;
      setActiveTab(newId);
   };

   const logout = () => {
      AuthService.logout();
      navigate("/");
   };

   // const helpDesk = () => {
   //    const organizationId = AuthService.getUser()?.organizationId;
   //    if(user)
   //    // document.body.classList.add("aside-minimize");
   //    navigate(`/help-desk/list?organizationId=${organizationId}`);
   // };

   const helpDesk = () => {
      if (user.roles[0].name === "SuperAdmin") {
         navigate(`/help-desk/list`);
      } else {
         navigate(`/help-desk/list`);
      }
   };

   useEffect(() => {
      if (location?.pathname?.includes("/assessment/self-assessment/")) {
         setActiveTab("self-assessments");
      } else if (location?.pathname?.includes("/assessment/guided-assessment/")) {
         setActiveTab("guided-assessment");
      } else if (location?.pathname?.includes("/results/results")) {
         setActiveTab("results");
      } else if (location?.pathname?.includes("/workspace/results/action-plan")) {
         setActiveTab("action-plan");
      } else if (location?.pathname?.includes("/help-desk/list")) {
         setActiveTab("help-desk");
      }
   }, [activeTab]);

   return (
      <>
         {/* begin::Aside */}
         <div
            id="kt_aside"
            className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig} ${
               isOrganizationManagement || isHelpDesk
                  ? "workspace-aside-organization-management"
                  : isGuidedConsultant || isStaff
                  ? "workspace-aside-guided-consultant"
                  : "workspace-aside"
            }`}
         >
            {/* begin::Primary */}
            <div className="aside-primary d-flex flex-column flex-row-auto">
               <Brand />
               <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull">
                  <ul className={`list-unstyled flex-column`} role="tablist">
                     {mainMenu.layout?.map((item, i) =>
                        AuthService.isAuthorized(item?.role ? item.role : []) ? (
                           <div key={i}>
                              <h4>{item.title}</h4>
                              {item.children.map((child) =>
                                 AuthService.isAuthorized(child?.role ? child.role : []) ? (
                                    child.type !== "banner" ? (
                                       <li
                                          key={child.id}
                                          className={`nav-item mb-3`}
                                          data-toggle="tooltip"
                                          data-placement="right"
                                          data-container="body"
                                          data-boundary="window"
                                       >
                                          {child.url ? (
                                             <NavLink
                                                exact="true"
                                                ref={menuItemRef}
                                                to={`${
                                                   AuthService.isAuthorized(
                                                      child?.adminRole ? child.adminRole : []
                                                   )
                                                      ? child?.adminUrl
                                                      : AuthService.isAuthorized([
                                                           UserRolesEnum.Consultant
                                                        ]) &&
                                                        child?.url?.includes("guided-assessment")
                                                      ? "/workspace/assessment/guided-assessment/list"
                                                      : child?.url
                                                }`}
                                                // isActive={(location) =>
                                                //    location.pathname === this.props.pathname
                                                // }
                                                className={`nav-link ${
                                                   location.pathname.includes(child.url)
                                                      ? "active"
                                                      : ""
                                                }`}
                                                onClick={(e) =>
                                                   location.pathname.includes(child.url)
                                                      ? e.preventDefault()
                                                      : handleTabChange(child.id)
                                                }
                                             >
                                                <div className="svg-icon svg-icon-lg">
                                                   <img
                                                      src={
                                                         process.env.PUBLIC_URL +
                                                         "/media/svg/icons/General/" +
                                                         child.icon
                                                      }
                                                      alt="icons"
                                                   />
                                                   <span className="nav-title">{child.title}</span>
                                                </div>

                                                {AuthService.isAuthorized([
                                                   UserRolesEnum.Consultant,
                                                   UserRolesEnum.Staff
                                                ]) ? (
                                                   <></>
                                                ) : (
                                                   <img
                                                      className={`arrow-menu`}
                                                      src={
                                                         process.env.PUBLIC_URL +
                                                         "/media/svg/icons/General/menuArrow.svg"
                                                      }
                                                      alt="menu arrow"
                                                   />
                                                )}
                                             </NavLink>
                                          ) : (
                                             <></>
                                          )}
                                       </li>
                                    ) : (
                                       <li
                                          key={child.id}
                                          className={`nav-banner ${activeTab === child.id &&
                                             "active"}`}
                                          data-toggle="tooltip"
                                          data-placement="right"
                                          data-container="body"
                                          data-boundary="window"
                                       >
                                          <NavLink
                                             to={child.link}
                                             className={`nav-link-banner`}
                                             activeclassname="active"
                                             onClick={() => handleTabChange(child.id)}
                                          >
                                             <div className="nav-link-banner-wrap">
                                                <span>{child.title}</span>
                                                <img
                                                   src={
                                                      process.env.PUBLIC_URL +
                                                      "/media/svg/icons/General/" +
                                                      child.icon
                                                   }
                                                   alt="icons"
                                                />
                                             </div>
                                          </NavLink>
                                       </li>
                                    )
                                 ) : (
                                    <></>
                                 )
                              )}
                           </div>
                        ) : (
                           <React.Fragment key={i}></React.Fragment>
                        )
                     )}
                  </ul>
                  {/* end::Nav */}
               </div>
               <div className="aside-footer">
                  {/* begin::Search */}
                  {layoutProps.extrasSearchDisplay && (
                     <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="toggle-search">Quick Search</Tooltip>}
                     >
                        <a
                           className="btn btn-icon btn-clean btn-lg mb-1"
                           id="kt_quick_search_toggle"
                        >
                           <span className="svg-icon svg-icon-lg">
                              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
                           </span>
                        </a>
                     </OverlayTrigger>
                  )}
                  {/* end::Search */}

                  {/* begin::Notifications */}
                  {layoutProps.extrasNotificationsDisplay && (
                     <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="toggle-notifications">Notifications</Tooltip>}
                     >
                        <a
                           className="btn btn-icon btn-clean btn-lg mb-1 position-relative"
                           id="kt_quick_notifications_toggle"
                           data-placement="right"
                           data-container="body"
                           data-boundary="window"
                        >
                           <span className="svg-icon svg-icon-lg">
                              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                           </span>
                        </a>
                     </OverlayTrigger>
                  )}
                  {/* end::Notifications */}
                  {/* Help Desk */}
                  {AuthService.isAuthorized([UserRolesEnum.SuperAdmin]) ||
                  AuthService.isAuthorized([UserRolesEnum.Staff]) ||
                  AuthService.isAuthorized([UserRolesEnum.NationalOrganization]) ||
                  AuthService.isAuthorized([UserRolesEnum.InternationalOrganization]) ? (
                     <div
                        className="nav-footer-help-desk-item"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                     >
                        <a
                           data-placement="right"
                           data-container="body"
                           data-boundary="window"
                           className={`nav-footer-help-desk ${
                              activeTab === "help-desk" ? "active" : ""
                           }`}
                           onClick={() => {
                              setActiveTab("help-desk");
                              helpDesk();
                           }}
                        >
                           <div className="nav-footer-item-wrap-help-desk">
                              <img
                                 src={
                                    process.env.PUBLIC_URL + "/media/svg/icons/General/tickets.svg"
                                 }
                                 alt="icons"
                              />
                              <span>
                                 {user?.roles[0]?.name === "SuperAdmin" ? "Tickets" : "Help Desk"}
                              </span>
                           </div>
                        </a>
                     </div>
                  ) : null}
                  <div
                     className={`nav-footer-item`}
                     data-placement="right"
                     data-container="body"
                     data-boundary="window"
                  >
                     <a
                        className={`nav-footer-banner ${activeTab === "logout" && "active"}`}
                        onClick={() => logout()}
                     >
                        <div className="nav-footer-item-wrap">
                           <img
                              src={process.env.PUBLIC_URL + "/media/svg/icons/General/logOut.svg"}
                              alt="icons"
                           />
                           <span>Log out</span>
                        </div>
                     </a>
                  </div>
                  {layoutProps.extrasLanguagesDisplay && <LanguageSelectorDropdown />}
               </div>
            </div>

            {layoutProps.asideSecondaryDisplay && (
               <>
                  {/* begin::Secondary */}
                  {isGuidedConsultant || isStaff ? (
                     <> </>
                  ) : (
                     <div
                        className={` ${
                           isOrganizationManagement || isHelpDesk
                              ? "organization-management-class"
                              : "aside-secondary"
                        } d-flex flex-row-fluid`}
                     >
                        {/* begin::Workspace */}
                        <div className="aside-workspace scroll scroll-push my-2">
                           <div className="tab-content">
                              <AsideMenu
                                 activeMenu={activeTab}
                                 // isActive={activeTab === menu?.layout[0].id}
                                 layoutProps={layoutProps}
                              />
                           </div>
                        </div>
                     </div>
                  )}
               </>
            )}
         </div>
         {/* end::Aside */}
      </>
   );
}
