import React from "react";
import { EmptyBox } from "../../../../../app/assets/icons";
import { Checkbox } from "antd";
import { Popover } from "@material-ui/core";
import moment from "moment-timezone";

const getTimeDifference = (createdAt) => {
   const currentTime = moment();
   const createdAtDate = moment.utc(createdAt).tz(moment.tz.guess());

   const duration = moment.duration(currentTime.diff(createdAtDate));

   const calculateTime = [
      { label: "year", value: duration.years() },
      { label: "month", value: duration.months() },
      { label: "week", value: Math.floor(duration.days() / 7) },
      { label: "day", value: duration.days() % 7 },
      { label: "hour", value: duration.hours() },
      { label: "minute", value: duration.minutes() }
   ];

   for (const unit of calculateTime) {
      if (unit?.value > 0) {
         return `${unit?.value} ${unit?.label}${unit?.value > 1 ? "s" : ""} ago`;
      }
   }

   return "just now";
};

const NotificationPopover = ({
   notifications,
   toggleBell,
   handleCloseBell,
   markNotificationAsRead,
   markAllNotificationsAsRead
}) => {
   return (
      <Popover
         className="arrow-menu"
         id="popover"
         open={Boolean(toggleBell)}
         anchorEl={toggleBell}
         onClose={handleCloseBell}
         style={{
            position: "fixed",
            top: "50px",
            maxHeight: "350px",
            right: "40px"
            // maxWidth: "400px"
         }}
         PaperProps={{
            style: {
               borderRadius: "8px"
            }
         }}
      >
         <div>
            <div
               className="d-flex"
               style={{
                  gap: "30px",
                  alignItems: "center",
                  padding: "20px",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #D9D9D9"
               }}
            >
               <p
                  style={{
                     fontWeight: 500,
                     fontSize: "18px",
                     fontFamily: "poppins",
                     color: "#3A3737",
                     margin: 0
                  }}
               >
                  Notifications
               </p>
               <div className="d-flex" style={{ gap: "10px", alignItems: "center" }}>
                  <Checkbox
                     checked={
                        notifications?.length > 0 &&
                        notifications?.every((notification) => notification?.isRead)
                     }
                     disabled={notifications?.every((notification) => notification?.isRead)}
                     onClick={markAllNotificationsAsRead}
                     width={15}
                     height={15}
                     style={{
                        cursor: "pointer",
                        color: notifications?.length === 0 ? "#BFBFBF" : "#5492FF",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "Poppins"
                     }}
                  >
                     Mark all as read
                  </Checkbox>
               </div>
            </div>
            <div>
               {notifications?.length !== 0 ? (
                  notifications?.map((notification) => (
                     <div
                        key={notification?.id}
                        style={{
                           borderBottom: "1px solid #D9D9D9"
                        }}
                     >
                        <div
                           style={{
                              padding: "12px 20px",
                              backgroundColor: notification?.isRead && "#F7F7F7"
                           }}
                        >
                           <div
                              className="d-flex"
                              style={{
                                 gap: "30px",
                                 alignItems: "center",
                                 justifyContent: "space-between"
                              }}
                           >
                              <div className="d-flex flex-column">
                                 <span
                                    style={{
                                       fontSize: "16px",
                                       color: "#3A3737",
                                       margin: 0,
                                       wordBreak: "break-word",
                                       fontWeight: 500
                                    }}
                                 >
                                    {notification?.notificationTitle}
                                 </span>
                                 <span
                                    style={{
                                       fontSize: "14px",
                                       color: "#9E9FA2",
                                       margin: 0,
                                       wordBreak: "break-word",
                                       fontWeight: 500
                                    }}
                                 >
                                    {notification?.notificationBody}
                                 </span>
                              </div>

                              {!notification?.isRead ? (
                                 <Checkbox
                                    onClick={() => markNotificationAsRead(notification?.id)}
                                 ></Checkbox>
                              ) : (
                                 <Checkbox defaultChecked disabled className="check-disabled" />
                              )}
                           </div>
                           <span
                              style={{
                                 fontSize: "14px",
                                 fontWeight: 500,
                                 color: "#979797"
                              }}
                           >
                              {getTimeDifference(notification?.createdDate)}
                           </span>
                        </div>
                     </div>
                  ))
               ) : (
                  <div
                     style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        padding: "20px",
                        borderTop: "1px solid #DFE6FF"
                     }}
                  >
                     <img src={EmptyBox} alt="" width={80} height={50} />
                     <p
                        style={{
                           margin: 0,
                           fontWeight: 500,
                           fontSize: "16px",
                           color: "#3A3737"
                        }}
                     >
                        No notifications here!
                     </p>
                     <p style={{ margin: 0, fontSize: "14px", color: "#979797" }}>
                        Everything is up-to-date!
                     </p>
                  </div>
               )}
            </div>
         </div>
      </Popover>
   );
};

export default NotificationPopover;
