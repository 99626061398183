import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { httpService } from "../../../base/services/httpService.service";
// import { LocalStorageService } from "../../../base/services/local-storage.service";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { LocalStorageService } from "../../../base/services/local-storage.service";
import { httpService } from "../../../base/services/httpService.service";
// import { EmailConfirmationIcon } from "../../../assets/icons";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { EyeInvisible } from "../../../assets/icons";
import { eye } from "react-icons-kit/feather/eye";
import { Checkbox, Spin } from "antd";
import Toast from "../../../base/components/Toast";

const initialValues = {
   newPassword: "",
   confirmNewPassword: ""
};

function ResetPassword() {
   const navigate = useNavigate();
   const location = useLocation();
   const userID = LocalStorageService.getItem("recoveryData");
   const searchParam = new URLSearchParams(location.search);
   const [confirmType, setConfirmType] = useState("password");
   const [confirmIcon, setConfirmIcon] = useState(eyeOff);
   const [type, setType] = useState("password");
   const [icon, setIcon] = useState(eyeOff);
   const [error, setError] = useState(null);
   const [checked, setChecked] = useState(false);
   const [loading, setLoading] = useState(false);

   const onChange = (e) => {
      setChecked(e.target.checked);
   };

   const validationSchema = Yup.object().shape({
      newPassword: Yup.string()
         .min(8, "Password must be at least 8 characters")
         .matches(
            /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-z]).*$/,
            "Password must contain at least one uppercase letter and one of the specified symbols !, @, #, $, %, ^, &, *"
         )
         .required("New password is required"),
      confirmNewPassword: Yup.string()
         .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
         .required("Confirm new password is required")
   });

   const resetPassword = async (values) => {
      // let formattedUserId = userID.replace(/"/g, "");
      try {
         setLoading(true);
         await httpService.post("/Accounts/password/reset", {
            userId: userID,
            newPassword: values?.newPassword,
            confirmNewPassword: values?.confirmNewPassword
         });
         navigate(`/auth/reset-password-recovered`);
      } catch (error) {
         if (error.response && error.response.data && error.response.data.errors) {
            const errorMessages = error.response.data.errors.map((error) => error.message);
            if (errorMessages.length > 0) {
               errorMessages.forEach((errorMessage) => {
                  Toast.error(errorMessage);
               });
            }
         } else {
            Toast.error("An unexpected error occurred. Please try again.");
         }
      } finally {
         setLoading(false);
      }
   };

   const handleTogglePassword = () => {
      if (type === "password") {
         setIcon(eye);
         setType("text");
      } else {
         setIcon(eyeOff);
         setType("password");
      }
   };
   const handleToggleConfirmPassword = () => {
      if (confirmType === "password") {
         setConfirmIcon(eye);
         setConfirmType("text");
      } else {
         setConfirmIcon(eyeOff);
         setConfirmType("password");
      }
   };

   return (
      <div
         className="pt-md-20 pr-md-10 pb-md-20 pl-md-10 custom-div"
         style={{
            margin: "0 !important",
            height: "auto",
            padding: "30px 20px",
            backgroundColor: "#fff"
         }}
      >
         <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => resetPassword(values)}
         >
            {({ handleSubmit, handleChange, values, errors, isSubmitting, touched }) => (
               <Form>
                  <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                     <div className="d-flex flex-column">
                        {/* <EmailConfirmationIcon /> */}
                        <p
                           style={{
                              color: "#4158CF",
                              fontWeight: 600,
                              fontSize: "26px",
                              margin: "0 0 8px "
                           }}
                        >
                           Reset your password
                        </p>
                        <div className="d-flex flex-column" style={{ gap: "8px" }}>
                           <p style={{ color: "#555", fontSize: "16px", margin: "0px" }}>
                              In order to protect your account, make sure your password:
                           </p>
                           <ul
                              style={{
                                 padding: "0 0 0 20px",
                                 display: "flex",
                                 flexDirection: "column",
                                 gap: "8px",
                                 fontSize: "16px",
                                 color: "#555"
                              }}
                           >
                              <li>Is at least 8 characters long</li>
                              <li>Have one uppercase character</li>
                              <li>Have one lowercase character</li>
                              <li>Have one number</li>
                              <li>Have one special character</li>
                           </ul>
                        </div>
                     </div>
                     <div className="form-group fv-plugins-icon-container">
                        <label
                           style={{
                              fontSize: "16px",
                              color: "#3A3737",
                              fontWeight: 500,
                              marginBottom: "10px"
                           }}
                        >
                           {/* <span style={{ color: "red" }}>* </span> */}
                           New password
                        </label>

                        <div className="password-section">
                           <Field
                              placeholder="Password"
                              type={type}
                              name="newPassword"
                              className="form-control"
                           />
                           <img
                              className="eyeButton"
                              onClick={handleTogglePassword}
                              src={EyeInvisible}
                              alt="showPasswordIcon"
                           />
                        </div>

                        {errors.newPassword && touched.newPassword && (
                           <div className="text-danger">{errors.newPassword}</div>
                        )}
                     </div>
                     <div className="form-group fv-plugins-icon-container">
                        <label
                           style={{
                              fontSize: "16px",
                              color: "#3A3737",
                              fontWeight: 500,
                              marginBottom: "10px"
                           }}
                        >
                           Confirm new password
                        </label>

                        <div className="password-section">
                           <Field
                              placeholder="Password"
                              type={confirmType}
                              name="confirmNewPassword"
                              className="form-control"
                           />
                           <img
                              className="eyeButton"
                              onClick={handleToggleConfirmPassword}
                              src={EyeInvisible}
                              alt="showPasswordIcon"
                           />
                        </div>

                        {errors.confirmNewPassword && touched.confirmNewPassword && (
                           <div className="text-danger">{errors.confirmNewPassword}</div>
                        )}

                        <div
                           className="d-flex align-items-center justify-content-end"
                           style={{ marginTop: "8px" }}
                        >
                           <Checkbox
                              checked={checked}
                              onChange={onChange}
                              style={{ lineHeight: "0px" }}
                           />
                           <p style={{ fontSize: "12px", color: "#777", margin: "0 0 0 8px" }}>
                              Remember me?
                           </p>
                        </div>
                     </div>

                     <div
                        className="form-group d-flex flex-wrap flex-center"
                        style={{ gap: "40px" }}
                     >
                        <Link to="/">
                           <button
                              type="button"
                              className="btn btn-outline-info"
                              style={{
                                 fontWeight: 500,
                                 fontSize: "14px",
                                 height: "44px"
                              }}
                           >
                              Cancel
                           </button>
                        </Link>
                        <button
                           // onClick={(e) => sentCode(e.target.value)}
                           type="submit"
                           //    disabled={isSubmitting}
                           className={`btn sign-btn`}
                           style={{ fontWeight: 500, width: "unset" }}
                        >
                           {loading ? <Spin /> : "Reset password"}
                        </button>
                     </div>
                  </div>
               </Form>
            )}
         </Formik>
      </div>
   );
}

export default ResetPassword;
