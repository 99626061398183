/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import objectPath from "object-path";
import React, { useContext, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { getBreadcrumbsAndTitle, useSubheader } from "../../_core/MetronicSubheader";
import { MenuItem, Popover } from "@material-ui/core";
import AuthService from "../../../../app/base/services/authentication.service";
import { UserRolesEnum } from "../../../../app/pages/auth/userRoles.enum";
import { BellRingSVG, ChevronDown, GoBack } from "../../../../app/assets/icons";
import { Avatar, Badge } from "antd";
import { NotificationContext } from "./notificationContext/NotificationContext";
import NotificationPopover from "./components/NotificationPopover";
import "./style.css";

export function SubHeader() {
   const uiService = useHtmlClassService();
   const location = useLocation();
   const subheader = useSubheader();
   const [anchorEl, setAnchorEl] = useState(null);
   const [toggleBell, setToggleBell] = useState(null);
   const user = AuthService?.getUser();
   const prevUrl = localStorage.getItem("previousUrl") || "/admin/organization";
   const isDetailPage = location.pathname.includes("/admin/organization/detail");
   const isViewActionPlan =
      location.pathname.includes("/view") && location.pathname.includes("action-plan");
   const isWorkspaceDetail =
      location.pathname.includes("/workspace/") || location.pathname.includes("/help-desk");
   const isEditSurveyDetail =
      location.pathname.includes("/admin/survey/edit-survey-details") ||
      location.pathname.includes("/admin/survey/add") ||
      location.pathname.includes("/admin/survey/import") ||
      location.pathname.includes("/admin/survey/edit/") ||
      location.pathname.includes("/admin/survey/test/");
   const selectedAssessmentId = localStorage.getItem("selectedAssessmentId");

   const logout = () => {
      AuthService.logout();
   };

   function handleClick(event) {
      setAnchorEl(event.currentTarget);
   }

   function handleClose() {
      setAnchorEl(null);
   }

   function handleToggleBell(event) {
      setToggleBell(event.currentTarget);
   }

   function handleCloseBell() {
      setToggleBell(null);
   }

   const layoutProps = useMemo(() => {
      return {
         config: uiService.config,
         subheaderFixed: objectPath.get(uiService.config, "subheader.fixed"),
         subheaderMobileToggle: objectPath.get(uiService.config, "subheader.mobile-toggle"),
         subheaderCssClasses: uiService.getClasses("subheader", true),
         subheaderContainerCssClasses: uiService.getClasses("subheader_container", true)
      };
   }, [uiService]);

   useLayoutEffect(() => {
      const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
      const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
      const breadcrumbs =
         aside && aside.breadcrumbs.length > 0 ? aside.breadcrumbs : header.breadcrumbs;
      subheader.setBreadcrumbs(breadcrumbs);
      subheader.setTitle(
         aside && aside.title && aside.title.length > 0 ? aside.title : header.title
      );

      // eslint-disable-next-line
   }, [location.pathname]);

   useEffect(() => {
      // Call setUser whenever the user changes
      setUser(AuthService?.getUser());
   }, []);

   const {
      unreadCount,
      notifications,
      markNotificationAsRead,
      markAllNotificationsAsRead,
      setUser
   } = useContext(NotificationContext);

   // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
   useEffect(() => {}, [subheader]);

   return (
      <div
         id="kt_subheader"
         className={`subheader py-2 py-lg-4 ${layoutProps.subheaderCssClasses}`}
         style={{ backgroundColor: "#fff" }}
      >
         <div
            className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
         >
            <div className="d-flex align-items-center flex-wrap mr-1">
               {/* begin::Mobile Toggle */}
               {layoutProps.subheaderMobileToggle && (
                  <button
                     className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
                     id="kt_subheader_mobile_toggle"
                  >
                     <span />
                  </button>
               )}
               {/* end::Mobile Toggle */}
            </div>
            {isDetailPage || isViewActionPlan || isWorkspaceDetail || isEditSurveyDetail ? (
               <div
                  className="pl-4 pl-md-10 pr-4 pr-md-10 d-flex w-100 align-items-center justify-content-between flex-wrap cursor-pointer"
                  style={{
                     gap: "10px"
                     // paddingLeft: "40px",
                  }}
               >
                  <Link
                     to={
                        isDetailPage
                           ? prevUrl
                           : isViewActionPlan
                           ? `/workspace/results/action-plan/${selectedAssessmentId}/`
                           : isEditSurveyDetail
                           ? "/admin/survey"
                           : "/admin/workspace"
                     }
                     className="d-flex align-items-center"
                     style={{ color: "#777777", fontSize: "14px", fontWeight: 500 }}
                  >
                     <img src={GoBack} alt="GoBack" style={{ marginRight: "10px" }} />
                     {isDetailPage
                        ? "Go back to organizations"
                        : isViewActionPlan
                        ? "Go back to action plan"
                        : isEditSurveyDetail
                        ? "Go back to surveys"
                        : "Go back to workspaces"}
                  </Link>
                  <div
                     className="subheader-rigth d-flex align-items-center"
                     style={{ gap: "20px" }}
                  >
                     {AuthService?.isAuthorized([
                        UserRolesEnum.SuperAdmin,
                        UserRolesEnum.Manager,
                        UserRolesEnum.Consultant,
                        UserRolesEnum.Staff,
                        UserRolesEnum.NationalOrganization
                     ]) && (
                        <>
                           <Badge count={unreadCount}>
                              <div onClick={handleToggleBell} className="bell">
                                 <img
                                    src={BellRingSVG}
                                    alt="link"
                                    aria-controls="popover"
                                    aria-haspopup="true"
                                    data-toggle="modal"
                                    data-target="#kt_chat_modal"
                                 />
                              </div>
                           </Badge>

                           <NotificationPopover
                              toggleBell={toggleBell}
                              handleCloseBell={handleCloseBell}
                              notifications={notifications}
                              markNotificationAsRead={markNotificationAsRead}
                              markAllNotificationsAsRead={markAllNotificationsAsRead}
                           />
                        </>
                     )}

                     <div onClick={handleClick} style={{ cursor: "pointer" }}>
                        <div className="username">
                           <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
                              <Avatar className="avatar">
                                 {user?.fullName.charAt(0).toUpperCase()}
                              </Avatar>
                              <div style={{ display: "flex", flexDirection: "column" }}>
                                 <div
                                    style={{
                                       // maxWidth: "65px",
                                       display: "inline-block"
                                    }}
                                 >
                                    <p className="fullnameParagraph">{user?.fullName}</p>
                                 </div>
                                 <div
                                    style={{
                                       // maxWidth: "65px",
                                       display: "inline-block"
                                    }}
                                 >
                                    <p className="roleParagraph">
                                       {user?.roles[0]?.name === "National Organization"
                                          ? "Org"
                                          : user?.roles[0]?.name}
                                    </p>
                                 </div>
                              </div>
                           </div>
                           <img
                              src={ChevronDown}
                              alt="ChevronDown"
                              aria-controls="simple-popover"
                              aria-haspopup="true"
                              data-toggle="modal"
                              data-target="#kt_chat_modal"
                           />
                        </div>
                     </div>
                     <Popover
                        id="simple-popover"
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        style={{
                           position: "fixed",
                           top: "50px"
                           // left: isWorkspaceURL ? "-15px" : "-33px"
                           // uncomment here and fix for mobile
                        }}
                        PaperProps={{
                           style: {
                              borderRadius: "8px",
                              border: "1.5px solid #C1C1C1",
                              padding: "4px 12px",
                              width: "180px"
                           }
                        }}
                     >
                        {AuthService?.isAuthorized([
                           UserRolesEnum.NationalOrganization,
                           UserRolesEnum.InternationalOrganization
                        ]) &&
                           location.pathname !== "/admin/workspace" && (
                              <Link to="/auth">
                                 <MenuItem onClick={logout}>Log out</MenuItem>
                              </Link>
                           )}
                        {AuthService?.isAuthorized([
                           UserRolesEnum.SuperAdmin,
                           UserRolesEnum.Manager,
                           UserRolesEnum.Consultant,
                           UserRolesEnum.Donor,
                           UserRolesEnum.Staff
                        ]) && (
                           <Link to="/auth">
                              <MenuItem onClick={logout}>Log out</MenuItem>
                           </Link>
                        )}
                        {AuthService?.isAuthorized([
                           UserRolesEnum.NationalOrganization,
                           UserRolesEnum.InternationalOrganization
                        ]) && (
                           <Link
                              to={`/workspace/organization-management/details/${user?.organizationId}`}
                           >
                              <MenuItem>My profile</MenuItem>
                           </Link>
                        )}
                     </Popover>
                  </div>
               </div>
            ) : (
               <div
                  className="subheader-rigth d-flex align-items-center"
                  style={{ gap: "20px", paddingRight: "40px" }}
               >
                  {AuthService?.isAuthorized([
                     UserRolesEnum.SuperAdmin,
                     UserRolesEnum.Manager,
                     UserRolesEnum.Consultant,
                     UserRolesEnum.Staff,
                     UserRolesEnum.NationalOrganization
                  ]) && (
                     <>
                        <Badge count={unreadCount}>
                           <div onClick={handleToggleBell} className="bell">
                              <img
                                 src={BellRingSVG}
                                 alt="link"
                                 aria-controls="popover"
                                 aria-haspopup="true"
                                 data-toggle="modal"
                                 data-target="#kt_chat_modal"
                              />
                           </div>
                        </Badge>

                        <NotificationPopover
                           toggleBell={toggleBell}
                           handleCloseBell={handleCloseBell}
                           notifications={notifications}
                           markNotificationAsRead={markNotificationAsRead}
                           markAllNotificationsAsRead={markAllNotificationsAsRead}
                        />
                     </>
                  )}
                  <div onClick={handleClick} style={{ cursor: "pointer" }}>
                     <div className="username">
                        <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
                           <Avatar className="avatar">
                              {user?.fullName.charAt(0).toUpperCase()}
                           </Avatar>
                           <div style={{ display: "flex", flexDirection: "column" }}>
                              <div
                                 style={{
                                    // maxWidth: "65px",
                                    display: "inline-block"
                                 }}
                              >
                                 <p className="fullnameParagraph">{user?.fullName}</p>
                              </div>
                              <div
                                 style={{
                                    // maxWidth: "65px",
                                    display: "inline-block"
                                 }}
                              >
                                 <p className="roleParagraph">
                                    {user?.roles[0]?.name === "National Organization"
                                       ? "Org"
                                       : user?.roles[0]?.name}
                                 </p>
                              </div>
                           </div>
                        </div>
                        <img
                           src={ChevronDown}
                           alt="ChevronDown"
                           aria-controls="simple-popover"
                           aria-haspopup="true"
                           data-toggle="modal"
                           data-target="#kt_chat_modal"
                        />
                     </div>
                  </div>

                  <Popover
                     id="simple-popover"
                     open={Boolean(anchorEl)}
                     anchorEl={anchorEl}
                     onClose={handleClose}
                     style={{
                        position: "fixed",
                        top: "50px"
                        // left: "-40px"
                     }}
                     PaperProps={{
                        style: {
                           borderRadius: "8px",
                           border: "1.5px solid #C1C1C1",
                           padding: "4px 12px",
                           width: "180px"
                        }
                     }}
                  >
                     {AuthService?.isAuthorized([
                        UserRolesEnum.SuperAdmin,
                        UserRolesEnum.Manager,
                        UserRolesEnum.Consultant,
                        UserRolesEnum.Donor,
                        UserRolesEnum.Staff
                     ]) && (
                        <Link to="/auth">
                           <MenuItem onClick={logout}>Log out</MenuItem>
                        </Link>
                     )}

                     {AuthService?.isAuthorized([
                        UserRolesEnum.NationalOrganization,
                        UserRolesEnum.InternationalOrganization
                     ]) && (
                        <Link to="/auth">
                           <MenuItem onClick={logout}>Log out</MenuItem>
                        </Link>
                     )}
                  </Popover>
               </div>
            )}
         </div>
      </div>
   );
}
