import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { httpService } from "../../../base/services/httpService.service";
import { LocalStorageService } from "../../../base/services/local-storage.service";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Toast from "../../../base/components/Toast";
import { Button, Input, Spin } from "antd";

const initialValues = {
   code: ""
};

function NgoVerification() {
   const navigate = useNavigate();
   const [code, setCode] = useState();
   const location = useLocation();
   const userID = LocalStorageService.getItem("userId");
   const searchParam = new URLSearchParams(location.search);
   const email = searchParam.get("email");
   const [loading, setLoading] = useState(false);

   const [error, setError] = useState(false);

   const ConfirmCodeSchema = Yup.object().shape({
      code: Yup.string()
         .matches(/^\d{6}$/, "Code must have 6 digits")
         .required("Code is required")
   });

   const sentCode = async (values) => {
      let formattedUserId = userID.replace(/"/g, "");
      try {
         setLoading(true);
         await httpService.post(
            "/Accounts/confirm-email",
            {
               userId: formattedUserId,
               code: values.code
            },
            () => {
               navigate("/auth/national-org-registration/step-three");
            }
         );
      } catch (error) {
         const errorMessages =
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.map((error) => error.message);
         if (errorMessages && errorMessages.length > 0) {
            errorMessages.forEach((errorMessage) => {
               Toast.error(errorMessage);
               setError(true);
            });
         } else {
            setError(false);
         }
      } finally {
         setLoading(false);
      }
   };

   const handleCodeChange = (e, setFieldValue) => {
      const value = e.target.value.replace(/\s+/g, "").replace(/[^0-9]/g, "");
      setFieldValue("code", value);
   };

   return (
      <div
         className="pt-md-15 pr-md-10 pb-md-15 pl-md-10 custom-div"
         style={{
            margin: "0 !important",
            padding: "30px 20px",
            height: "auto",
            maxHeight: "470px",
            backgroundColor: "#fff"
         }}
      >
         <Formik
            initialValues={initialValues}
            validationSchema={ConfirmCodeSchema}
            onSubmit={(values) => sentCode(values)}
         >
            {({ handleSubmit, setFieldValue, handleChange, values, errors, isSubmitting }) => (
               <Form>
                  <div style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
                     <div className="text-center d-flex flex-column align-items-center">
                        <p
                           className="mb-4"
                           style={{ color: "#4158CF", fontWeight: 600, fontSize: "26px" }}
                        >
                           Verification code
                        </p>
                        <p style={{ color: "#555", fontSize: "16px", margin: "0px" }}>
                           Please enter the verification code that we sent to
                        </p>
                        <p style={{ color: "#3A3737", fontSize: "16px", fontWeight: 700 }}>
                           {email}
                        </p>
                        {error && <div className="error-message">{error}</div>}
                        <Input
                           style={{
                              borderRadius: "8px",
                              border: errors.code || error ? "1px solid red" : "1px solid #979797",
                              width: "100%",
                              maxWidth: "325px",
                              height: "70px",
                              padding: "10px",
                              color: "#003DAA",
                              fontSize: "40px",
                              fontWeight: 700,
                              letterSpacing: "6.8px",
                              textAlign: "center",
                              marginTop: "15px"
                           }}
                           className={`form-control`}
                           maxLength={6}
                           onChange={(e) => handleCodeChange(e, setFieldValue)}
                           value={values?.code}
                           autoComplete="off"
                        />
                        {error && <div className="error-message mt-4">The code is incorrect</div>}
                        {errors.code && <div className="error-message mt-4">{errors.code}</div>}
                     </div>
                     <div
                        className="form-group d-flex flex-wrap flex-center"
                        style={{ gap: "40px" }}
                     >
                        <Link to="/">
                           <Button
                              type="button"
                              className="btn btn-outline-info"
                              style={{
                                 fontWeight: 500,
                                 fontSize: "14px",
                                 height: "44px"
                              }}
                           >
                              Cancel
                           </Button>
                        </Link>
                        <Button
                           onClick={handleSubmit}
                           disabled={isSubmitting}
                           className={`btn sign-btn`}
                           style={{ fontWeight: 500 }}
                        >
                           {loading ? <Spin /> : "Verify code"}
                        </Button>
                     </div>
                     <p
                        style={{
                           textAlign: "center",
                           color: "#777",
                           fontSize: "14px",
                           fontWeight: 400
                        }}
                     >
                        If you didn’t receive a verification code,{" "}
                        <Link
                           to="/resend-verification"
                           style={{ color: "#5492FF", textDecoration: "none" }}
                        >
                           click here to resend it
                        </Link>{" "}
                        or send us an email at{" "}
                        <a
                           href="mailto:info@kaptechgroup.com"
                           style={{ color: "#555", fontWeight: 600 }}
                        >
                           info@kaptechgroup.com
                        </a>
                        .
                     </p>
                  </div>
               </Form>
            )}
         </Formik>
      </div>
   );
}

export default NgoVerification;
