import React, { useState, useEffect } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { httpService } from "../../base/services/httpService.service";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { BackIcon, EyeInvisible } from "../../assets/icons";
import { registerDonor } from "./authSlice";
import { useDispatch } from "react-redux";
import TermsConditionsModal from "../../components/TermsConditionsModal";
import { useSelector } from "react-redux";
import Toast from "../../base/components/Toast";
import { Spin } from "antd";

const initialValues = {
   organizationName: "",
   baseCountryId: null,
   email: "",
   password: "",
   confirmPassword: "",
   invitationCode: ""
};

function RegistrationOfInternationalDonor() {
   const [countryList, setCountryList] = useState([]);
   const [type, setType] = useState("password");
   const [icon, setIcon] = useState(eyeOff);
   const [confirmType, setConfirmType] = useState("password");
   const [confirmIcon, setConfirmIcon] = useState(eyeOff);
   const [modalVisible, setModalVisible] = useState(false);

   const loading = useSelector((state) => state.auth.isLoading);

   const openTermsModal = () => {
      setModalVisible(!modalVisible);
   };

   const dispatch = useDispatch();
   const navigate = useNavigate();
   // uppercase edhe 1 character
   const validationSchema = Yup.object().shape({
      email: Yup.string()
         .email("Invalid email address")
         .required("Email is required"),
      password: Yup.string()
         .min(8, "Password must be at least 8 characters")
         .matches(
            /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-z]).*$/,
            "Password must contain at least one uppercase letter and one of the specified symbols !, @, #, $, %, ^, &, *"
         )
         .required("Password is required"),
      confirmPassword: Yup.string()
         .oneOf([Yup.ref("password"), null], "Passwords must match")
         .required("Confirm Password is required"),
      organizationName: Yup.string().required("Organization Name is required"),
      baseCountryId: Yup.object().shape({
         value: Yup.string().required("Base country is required"),
         label: Yup.string().required("Base country is required")
      }),
      invitationCode: Yup.string()
         .min(12, "Invitation code must be at least 12 characters")
         .max(12)
         .required("Invitation Code is required"),
      acceptTerms: Yup.bool().oneOf([true], "You must accept the terms and conditions")
   });

   useEffect(() => {
      httpService.get(`/countries`, (data) => {
         const countryData = data.data.map((country) => {
            return {
               value: country.id,
               label: country.name
            };
         });
         setCountryList(countryData);
      });
   }, []);

   const onSubmit = (values) => {
      try {
         // setLoading(true);
         const baseCountryIdValue = values.baseCountryId.value;

         const updatedValues = {
            ...values,
            baseCountryId: baseCountryIdValue
         };
         dispatch(
            registerDonor(updatedValues, () => {
               navigate(`/auth/login`);
            })
         );
      } catch (error) {
         if (error.response || error.response.data || error.response.data.errors) {
            const errorMessage = error.response.data.errors.message;
            Toast.error(errorMessage);
         }
      }
   };

   const handleTogglePassword = () => {
      if (type === "password") {
         setIcon(eye);
         setType("text");
      } else {
         setIcon(eyeOff);
         setType("password");
      }
   };
   const handleToggleConfirmPassword = () => {
      if (confirmType === "password") {
         setConfirmIcon(eye);
         setConfirmType("text");
      } else {
         setConfirmIcon(eyeOff);
         setConfirmType("password");
      }
   };
   return (
      <>
         <div className="p-8 p-md-10 pt-xl-10 pb-xl-20 pl-xl-40 pr-xl-40">
            <Link
               to="/auth/registration"
               className="d-flex align-items-center"
               style={{
                  color: "#5492FF",
                  // margin: "50px 0 0 16px",
                  fontSize: "16px",
                  fontWeight: 500,
                  cursor: "pointer"
               }}
            >
               <img src={BackIcon} alt="Go back" style={{ marginRight: "10px" }} />
               Go back to profile selection
            </Link>
            <Formik
               initialValues={initialValues}
               onSubmit={onSubmit}
               validationSchema={validationSchema}
            >
               {({ values, handleChange, setFieldValue, errors, touched }) => (
                  <Form>
                     <div
                        style={{
                           display: "flex",
                           flexDirection: "column",
                           marginTop: "40px",
                           gap: "32px"
                        }}
                     >
                        <p
                           style={{
                              fontSize: "26px",
                              color: "#4158CF",
                              fontWeight: 600,
                              margin: "0px"
                           }}
                        >
                           Create an account{" "}
                           <span style={{ color: "#5492FF" }}>as an International donor</span>
                        </p>
                        <div>
                           <p style={{ fontSize: "18px", fontWeight: 600, color: "#4158CF" }}>
                              Login details
                           </p>

                           <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                              <div className="form-group fv-plugins-icon-container">
                                 <label
                                    style={{
                                       fontSize: "16px",
                                       color: "#3A3737",
                                       fontWeight: 500,
                                       marginBottom: "10px"
                                    }}
                                 >
                                    <span style={{ color: "red" }}>* </span>
                                    Email
                                 </label>
                                 <Field
                                    placeholder="yourmail@gmail.com"
                                    className={`form-control`}
                                    type="email"
                                    name="email"
                                    autocomplete="off"
                                 />
                                 {errors.email && touched.email && (
                                    <div className="text-danger">{errors.email}</div>
                                 )}
                              </div>
                              <div className="form-group fv-plugins-icon-container">
                                 <label
                                    style={{
                                       fontSize: "16px",
                                       color: "#3A3737",
                                       fontWeight: 500,
                                       marginBottom: "10px"
                                    }}
                                 >
                                    <span style={{ color: "red" }}>* </span>
                                    Password
                                 </label>

                                 <div className="password-section">
                                    <Field
                                       placeholder="Password"
                                       type={type}
                                       name="password"
                                       className="form-control"
                                    />
                                    <img
                                       className="eyeButton"
                                       onClick={handleTogglePassword}
                                       src={EyeInvisible}
                                       alt="showPasswordIcon"
                                    />
                                 </div>

                                 {errors.password && touched.password && (
                                    <div className="text-danger">{errors.password}</div>
                                 )}
                              </div>
                              <div className="form-group fv-plugins-icon-container">
                                 <label
                                    style={{
                                       fontSize: "16px",
                                       color: "#3A3737",
                                       fontWeight: 500,
                                       marginBottom: "10px"
                                    }}
                                 >
                                    <span style={{ color: "red" }}>* </span>
                                    Confirm password
                                 </label>

                                 <div className="password-section">
                                    <Field
                                       placeholder="Password"
                                       type={confirmType}
                                       name="confirmPassword"
                                       className="form-control"
                                    />
                                    <img
                                       className="eyeButton"
                                       onClick={handleToggleConfirmPassword}
                                       src={EyeInvisible}
                                       alt="showPasswordIcon"
                                    />
                                 </div>

                                 {errors.confirmPassword && touched.confirmPassword && (
                                    <div className="text-danger">{errors.confirmPassword}</div>
                                 )}
                              </div>
                           </div>
                        </div>

                        <div>
                           <p style={{ fontSize: "18px", fontWeight: 600, color: "#4158CF" }}>
                              Organization details
                           </p>
                           <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                              <div className="form-group fv-plugins-icon-container">
                                 <label
                                    style={{
                                       fontSize: "16px",
                                       color: "#3A3737",
                                       fontWeight: 500,
                                       marginBottom: "10px"
                                    }}
                                 >
                                    <span style={{ color: "red" }}>* </span>
                                    Organization name
                                 </label>
                                 <Field
                                    placeholder="Enter organization's name"
                                    type="text"
                                    name="organizationName"
                                    className="form-control"
                                    autocomplete="off"
                                 />
                                 {errors.organizationName && touched.organizationName && (
                                    <div className="text-danger">{errors.organizationName}</div>
                                 )}
                              </div>
                              <div className="form-group fv-plugins-icon-container">
                                 <label
                                    style={{
                                       fontSize: "16px",
                                       color: "#3A3737",
                                       fontWeight: 500,
                                       marginBottom: "10px"
                                    }}
                                 >
                                    <span style={{ color: "red" }}>* </span>
                                    Base country
                                 </label>
                                 <Field
                                    placeholder="Select your base country"
                                    as={Select}
                                    name="baseCountryId"
                                    value={values.baseCountryId}
                                    options={countryList}
                                    onChange={(selectedOption) => {
                                       setFieldValue("baseCountryId", selectedOption);
                                    }}
                                    autocomplete="off"
                                 />

                                 {errors.baseCountryId && touched.baseCountryId && (
                                    <div className="text-danger">{errors.baseCountryId.value}</div>
                                 )}
                              </div>
                              <div className="form-group fv-plugins-icon-container">
                                 <label
                                    style={{
                                       fontSize: "16px",
                                       color: "#3A3737",
                                       fontWeight: 500,
                                       marginBottom: "10px"
                                    }}
                                 >
                                    <span style={{ color: "red" }}>* </span>
                                    Invitation code
                                 </label>
                                 <Field
                                    placeholder="Enter your invitation code"
                                    type="text"
                                    name="invitationCode"
                                    className="form-control"
                                    validate={(value) => {
                                       if (value && !/^[a-zA-Z0-9-]+$/i.test(value)) {
                                          return "Invalid invitation code format";
                                       }
                                       return "";
                                    }}
                                    autocomplete="off"
                                 />
                                 {errors.invitationCode && touched.invitationCode && (
                                    <div className="text-danger">{errors.invitationCode}</div>
                                 )}
                                 <p
                                    style={{
                                       color: "#979797",
                                       fontSize: "12px",
                                       margin: "8px 0 0 0"
                                    }}
                                 >
                                    If you don't have an invitation code please send us an email
                                    here:
                                    <a
                                       href="mailto:info@kaptechgroup.com"
                                       style={{ color: "#979797", fontSize: "12px" }}
                                    >
                                       {" "}
                                       info@kaptechgroup.com
                                    </a>
                                 </p>
                              </div>

                              {/* <div className="form-group fv-plugins-icon-container">
                              <label
                                 style={{
                                    fontSize: "16px",
                                    color: "#3A3737",
                                    fontWeight: 500,
                                    marginBottom: "10px"
                                 }}
                              >
                                 <span style={{ color: "red" }}>* </span>
                                 Invitation code
                              </label>
                              <Field
                                 type="text"
                                 placeholder="Enter your invitation code"
                                 name="invitationCode"
                                 value={values.invitationCode}
                                 validate={(value) => {
                                    if (value && !/^[a-zA-Z0-9-]+$/i.test(value)) {
                                       return "Invalid invitation code format";
                                    }
                                    return ""; // No validation error
                                 }}
                              />
                              {errors.invitationCode && touched.invitationCode && (
                                 <div className="text-danger">{errors.invitationCode}</div>
                              )}
                              <p style={{ color: "#979797", fontSize: "12px" }}>
                                 If you don't have an invitation code please send us an email here:
                                 info@kaptechgroup.com
                              </p>
                           </div> */}
                           </div>
                        </div>
                        <div>
                           <div style={{ display: "flex", alignItems: "flex-start" }}>
                              <Field type="checkbox" name="acceptTerms" required className="m-1" />
                              <p style={{ margin: 0 }}>
                                 I have read and agreed with the{" "}
                                 <Link
                                    onClick={openTermsModal}
                                    to="#"
                                    rel="noopener noreferrer"
                                    style={{
                                       color: "#5492FF",
                                       textDecoration: "underline",
                                       margin: "0 4px"
                                    }}
                                 >
                                    Terms of Service, Privacy Policy
                                 </Link>
                                 and{" "}
                                 <Link
                                    onClick={openTermsModal}
                                    to="#"
                                    rel="noopener noreferrer"
                                    style={{
                                       color: "#5492FF",
                                       textDecoration: "underline",
                                       margin: "0 4px"
                                    }}
                                 >
                                    Cookie Policy.
                                 </Link>
                              </p>
                           </div>
                           {errors.acceptTerms && touched.acceptTerms && (
                              <div className="text-danger">{errors.acceptTerms}</div>
                           )}
                        </div>
                        <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
                           <button
                              id="kt_login_signin_submit"
                              type="submit"
                              className={`btn sign-btn font-weight-bold my-3`}
                           >
                              {loading ? <Spin /> : "Sign up"}
                           </button>
                        </div>
                     </div>
                  </Form>
               )}
            </Formik>
         </div>
         <TermsConditionsModal visible={modalVisible} onCancel={openTermsModal} />
      </>
   );
}
export default RegistrationOfInternationalDonor;
