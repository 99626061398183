export function getColor(score, maxScore) {
   if (maxScore) {
      const percent = score * (100 / maxScore);
      const hue = ((percent / 100) * 120).toString(10);
      return ["hsl(", hue, ",100%,42%)"].join("");
   } else {
      return "#00D600";
      // return "#DDE9FF";
   }
}

export function getColorBackground(score, maxScore) {
   if (maxScore) {
      const percent = score * (100 / maxScore);
      const hue = ((percent / 100) * 120).toString(10);
      return ["hsl(", hue, ",100%,97%)"].join("");
   } else {
      return "#00D60012";
      // return "#DDE9FF";
   }
}

export function getPercentage(completed, number) {
   if (number > 0) {
      return completed * (100 / number);
   } else {
      return 0;
   }
}
